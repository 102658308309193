/* MOBILE NAVIGATION */

.navigation--mobile {
    position: fixed;
    display: block;
    z-index: 999;
    padding: 1rem;
    background-color: #FFF;
    width: 80%;
    height: 100%;
    top: 0;
    transform: translateX(-100%);
    transition: transform 0.2s ease-in-out;

    &.--active {
        transform: translateX(0);
        transition: transform 0.2s ease-in-out;
        left: 0;
        border-right: solid 1px #CCC;
        overflow: scroll;
    }

    &__list {
        list-style-type: disc;
        padding-left: 1rem;
        li {
            cursor: pointer;
        }

        + ul {
            margin-top: 1rem;
        }
    }

    &__close {
        position: absolute;
        right: 0;
        top: 0;
        padding: 1rem;
    }

    .block--categories__subcats {

    }

    .block--categories__subcat {
        display: flex;
        flex-direction: row;
        opacity: 0.6;
        &::before {
            background-color: rgba(#000, 0.6);
            border-radius: 50%;
            content: "";
            display: inline-block;
            margin-top: 0.6rem;
            margin-right: 10px;
            height: 0.35rem;
            width: 0.35rem;
        }
    }
}