/* BLOCK REVIEW */

.block {
    &--review {
        max-width: 25rem;
        margin: auto;
        
        &__header {
            margin-bottom: 1rem;
        }

        &__title {
            display: block;
            font-weight: bold;
        }

        &__author {
            display: block;
        }

        &__content {
            margin-top: 1rem;
            &-title {
                font-weight: bold;
                margin: 1rem 0;
            }

            &-text {

            }

        }

        &__reviewer {
            display: flex;
            flex-direction: row;
            &-photo {
                width: 5rem;
                margin-right: 1rem;
                img {
                    width: 100%;
                    border: solid 1px #CCC;
                }
            }
            &-specs {
                dl {
                    margin: 0;
                    dt {
                        font-weight: bold;
                        font-size: 0.75rem;
                    }
        
                    dd {
                        margin: 0 0 0.5rem;
                        padding: 0;
                    }
                }
            }
        }
    }
}