/* LOADER */

.loader-wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    margin-left: 50vw;
    margin-top: 50vh;
    transform: translate(-50%, -50%);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}