/* BLOCK SIDEBAR */

.block {
    &--sidebar {
        padding: 0;
        border: none;
        flex: none;
        display: block;
    }
}

.block--sidebar,
.content-wrapper__sidebar {
    .block {
        &__block-title {
            margin-bottom: 1rem;
            font-size: 1rem;
            font-weight: bold;
            text-align: center;
            display: block;
        }
        &__block-subtitle {
            display: block;
            margin-bottom: 1rem;
            font-weight: normal;
            font-size: $fontSizeReduced;
            &--bold {
                display: block;
                font-size: $fontSizeReduced;
                //font-size: 1rem;
                font-weight: bold;
            }
        }

        &__block-content {
            line-height: $baseLineHeight;
        }

        &__block-list {
            padding: 0;
            margin-bottom: 1.5rem;
            text-align: left;

            li {
                margin: 0.1rem 0;
                cursor: pointer;
            }
        }

        &--top10 {
            p {
                font-size: $fontSizeReduced;
            }

            .link--read-more {
                margin-top: 2rem;
            }
        }
    }
}

.content-wrapper__sidebar--left {
    .block {
        &__block-title {
            display: none;
            @media only screen and (min-width: (64px*12)) {
                display: block;
            }
        }
    }
}

.block {
    &--sidebar {
        &__link--expand {
            display: block;
        }
    }

    &--categories {
        border-top: none;

        &__cat {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            text-transform: lowercase;

            span {
                display: flex;
                align-items: center;
                position: relative;

                &:after {
                    content: "";
                    display: block;
                    position: relative;
                    right: -0.35rem;
                    top: 0.05rem;
                    @include arrowSolid(rgba(#000, 0.5), right, 0.30rem);
                }
            }
        }

        &__subcats {
            margin-left: 0.5rem;
        }

        &__lang {
            text-transform: capitalize;
        }

        .icon--after {
            display: block;
            @media only screen and (min-width: $triggerGridChange) {
                display: none;
            }
        }

        .arrow--down {
            @include arrow(#000, down, 0.1rem);
        }
    }
}

.content-wrapper__sidebar {
    padding: 1rem;

    .block {
        margin-bottom: 2rem;
        padding-bottom: 1rem;
        border-top: solid 1px #666;
        min-height: 0;
        text-align: center;

        &:first-child {
            border-top: 0;
        }

        &--clean {
            border-top: none;
        }

        &--authors,
        &--categories,
        &--product-groups {
            margin-bottom: 1rem;
            font-size: $fontSizeReducedIntermediate;

            &__subgroups {
                padding: 0 0 0 1rem;
                li {
                    list-style-type: disc;
                }
            }

            &__subgroup {
                &.--active {
                    //color: $txtColorBase-branded;
                    @include color('txtColorContent-dark');
                }

            }

            &.--active {
                //color: $txtColorBase-branded;
                @include color('txtColorContent-dark');
            }

            .block__block-title {
                margin-bottom: 0;
            }

            .block__block-list {
                //display: none;
                &.--active {
                    display: block;
                    //@include foldOutList(5);
                }
            }
        }

        &--categories {
            &__cat {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                &.--active {
                    @include color('txtColorContent-dark');
                    font-weight: bold;
                }
                
                &:hover {
                    @include color('txtColorContent-dark');

                }
            }

            .icon--after {

            }

            .arrow--down {
                @include arrow(#000, down, 0.1rem);
            }

            &__subcats {
                li {
                    display: flex;
                    flex-direction: row;
                    font-size: $fontSizeReduced;
                    &::before {
                        background-color: rgba(#000, 0.2);
                        border-radius: 50%;
                        content: "";
                        display: inline-block;
                        margin-top: 0.6rem;
                        margin-right: 10px;
                        height: 0.35rem;
                        width: 0.35rem;
                        transform: translate(2px, -1px);
                    }
                }

                &.--active {
                    //display: block;
                    &::before {
                        background-color: rgba(#000, 0.6);
                    }
                }
            }

            &__subcat {
                text-transform: lowercase;
                &.--active,
                &:hover {
                    font-weight: bold;
                    @include color('txtColorContent-dark');
                }

                &.--active {
                    //display: block;
                    color: $txtColorBase-branded;
                    &::before {
                        background-color: rgba($color-dark-sb, 1);
                    }
                }
            }
        }

        &--newest-books,
        &--top-20 {
            .block__block-title {
                margin-bottom: 0;
            }
        }

    }

    .block--loggedin {
        margin-bottom: 1rem;
        &__link {
            display: block;
            line-height: 1.3rem;
        }
    }

    .block:not(:first-child) {
        .block__block-title {
            display: inline-block;
            background-color: $bgColor-base-lightdark;
            padding: 0 0.5rem;
            transform: translateY(-0.9rem);
        }

        .block__block-subtitle {
            margin-top: -1.9rem; 
        }

        &.block--newsletter {
            .block__block-subtitle {
                margin-top: -1rem; 
            }
            .block__block-content {
                margin-bottom: -2rem; 
            }
        }

        &.block--translations{
            .block__block-title {
                margin-bottom: 0.5rem; 
            }
        }

        .block__block-content {
            transform: translateY(-1rem);
            margin-bottom: -1rem;
        }
    }

    .block__block-subtitle {
        margin-top: -1rem;
    }
}