/* GRID */

html {
    -webkit-overflow-scrolling: touch;
}

body {
    background-color: rgba($bgColor-base, 0.5);
}

.app-content {
    min-height: 100vh;
}

// MAIN
.wrapper-header {
    position: fixed;
    top: 0;
    z-index: $zHeader;
    width: 100%;
    min-height: 7rem; 
    &.--feature-all {
        height: 12.25rem;
    }
    @include background-color('bgColor-base');
    @media only screen and (min-width: 640px) {
        &.--feature-all {
            height: 11rem;
        }
    }
    @media only screen and (min-width: $triggerMobileNav) {
        height: 8rem;
        &.--feature-all {
            height: 9.5rem;
        }
    }
}

.wrapper-main {
    //opacity: 0;

}

.content-wrapper {
    position: relative;
    z-index: $zContent;
    background-color: #fff;

    &__sidebar {
        flex: 1;
        flex-direction: column;
        padding-top: 1rem;
        width: calc(100% - 2rem);
        margin: 0 auto;
        background-color: $bgColor-base-lightdark;
        @media only screen and (min-width: $triggerMobileNav) {
            width: auto;
            max-width: 13rem;
        }
    }

    &__middle {
        position: relative;
        display: block;
        margin: 0;
        padding: 1.5rem 1rem 4rem;
        background-color: #FFF;
        @media only screen and (min-width: (64px*10)) {
            margin: 0 1rem;
        }
        @media only screen and (min-width: $triggerMobileNav) {
            flex: 5;
            order: 2;
            display: flex;
            flex-direction: column;
            width: calc(100% - 40rem);
        }
    }

    &__content {
        width: 100%;
        max-width: 48rem;
        margin: 0 auto;
        flex-direction: column;
    }

    .content {
        + .content {
            margin-top: 2rem;
        }
    }
}

footer {
    //opacity: 0;
}

.wrapper-footer {
    //position: fixed;
    z-index: $zFooter;
    bottom: 0;
    width: 100%;
    min-height: 6rem;  
    //margin-top: 5rem;
    background-color: #DDD;
    
}

.wrapper-header {
    .navigation {
        display: none;
        @media only screen and (min-width: $triggerMobileNav) {
            display: flex;
        }
    }
}

.wrapper-navigation {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.content-wrapper,
.wrapper-content {
    display: flex;
    flex-direction: column;
    order: 2;
    @media only screen and (min-width: $triggerMobileNav) {
        flex-direction: row;
    }
    &__sidebar {
        &--left {
            display: none;
            order: 1;
            flex: 2;
            @media only screen and (min-width: $triggerMobileNav) {
                display: flex;
            }
        }

        &--right {
            order: 3;
            flex: 2;
        }

        + .content-wrapper__content {
            flex: 5;
            margin-left: 0;
            margin-right: 0;
            background-color: $bgColor-base-light;
        }
    }

    &__segments {
    }

    &__segment {
        display: block;
        width: 100%;
        + .content-wrapper__segment {
            margin-top: 2rem;
        }

        &--popular {
            + .content-wrapper__segment {
                margin-top: 0;
                .blocks {
                    border-top: none;
                    margin-top: 1rem;
                }
            }
        }

        &--sticky {
            display: flex;
            position: fixed;
            background-color: #FFF;
            z-index: 100;
            width: auto;
            flex-direction: row;
            border: solid 1px #CCC;
            background-color: #FFF;
            right: 0;
            padding: 1rem 1rem 0.7rem;
            border-radius: 0 0 0.5rem 0.5rem;
            margin-top: -100%;

            animation: moveActionsOut 1s;
            -moz-animation: moveActionsOut 1s; /* Firefox */
            -webkit-animation: moveActionsOut 1s; /* Safari and Chrome */ 

            &.--active {
                display: flex;
                animation: moveActionsIn 0.75s;
                -moz-animation: moveActionsIn 0.75s; /* Firefox */
                -webkit-animation: moveActionsIn 0.75s; /* Safari and Chrome */ 
                margin-top: 0;
            }

            .block-content--book__cart-actions {
                display: flex;
            }

            @at-root .app-content--mobile & {
                top: 0;
            }
        }
    }

    &__content {
        &--cart {
            margin: 0 1rem;
            width: auto;
            @media only screen and (min-width: (64px * 10)) {
                display: block;
                margin-left: 5rem;
                margin-right: 5rem;
            }
            @media only screen and (min-width: (64px * 16)) {
                display: flex;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .block--quote {
            display: none;
            @media only screen and (min-width: (64px * 12)) {
                display: block;
            }
        }
    } 
}

.content-segment {
    + .content-segment,
    + .content-wrapper__segment {
        margin-top: 2rem;
    }

    &__header {
        + .content-segment {
            margin-top: 1.5rem;
        }
        + .content-segment__content {
            margin-top: 1.5rem;
        }
    }
}

.shopping-cart {
    + .block--quote {
        margin-top: 2rem;
    }
}

.cart__actions {
    + .block--quote {
        margin-top: 3rem;
    }
}

@keyframes moveActionsIn{
    from {margin-top: -100%;} 
    to { margin-top: 0;} 
}
@-moz-keyframes moveActionsIn{
    from {margin-top: -100%;} 
    to { margin-top: 0;} 
}
@-webkit-keyframes moveActionsIn{
    from {margin-top: -100%;} 
    to { margin-top: 0;} 
}

@keyframes moveActionsOut {
    from {margin-top: 0;} 
    to { margin-top: -100%;} 
}

@-moz-keyframes moveActionsOut {
    from {margin-top: 0;} 
    to { margin-top: -100%;} 
}

@-webkit-keyframes moveActionsOut {
    from {margin-top: 0;} 
    to { margin-top: -100%;} 
}