.content-events,
.book__description {
    .content__logos {
        width: 80%;
        margin: auto;
        display: flex;
        flex-direction: row;
    }

    .content__logo {
        max-width: 50%;
        margin: 1rem;
        padding: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: solid 1px #DDD;

        &.--color {
            background-color: $bgColor-base-lightdark;
        }

        img {
            width: 100%;
            max-width: 15rem;
        }
    }

    .content__event-data {
        .event-data {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin: 0 -1rem;
            padding: 1rem;
            border: solid 1px #DDD;

            &__item-header {
                //grid-column: 1 / span 2;
                h3 {
                    margin-bottom: 0;
                    font-size: 1rem;
                }
            }
            &__item {
                h4 {
                    margin-top: 0;
                }
                p {
                    + .event-data__actions {
                        margin-top: -0.5rem;
                    }
                }
            }
            &__actions {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 1rem;
            }
        }
    }

    .form--event {
        max-width: 100%;
    }

    .form--event__guest {
        background-color: $bgColor-base-lightdark;
        margin: 1.5rem -1rem;
        padding: 1rem;
    }

    .content-segment__content--code-check {
        display: none;
        &.--active {
            display: block;
            margin-bottom: 2rem;
        }
    }

    .content-segment__content--form {
        display: none;
        &.--active {
            display: block;
        }
    }
}