.order-summary {
    width: 100%;
    
    &__id {
        font-weight: bold;
        margin-top: -0.25rem;
    }

    &__tracktrace {
        margin-top: -0.15rem;
        margin-bottom: 1rem;
    }

    &__discount {
        display: block;
        font-size: $fontSizeReduced;
    }

    &__content {
        display: flex;
        border-bottom: solid 1px #CCC;
        margin-bottom: 0.25rem;
        padding-bottom: 0.25rem;
    }

    &__image {
        width: 3rem;
        margin-right: 1rem;
        img {
            width: 100%;
        }
    }

    &__info {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        > span {
            margin-top: -0.25rem;
        }
    }

    &__total {
        margin-top: 1rem;
    }

    &__sku {
        display: block;
        font-size: $fontSizeReduced;
        line-height: 1;
    }

    &__descr {
        margin: 0;
        font-size: $fontSizeReduced;
    }

    &__totals {
        justify-content: flex-end;
    }

    &__actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 1rem;
        flex-wrap: wrap;

        p {
            margin-bottom: 0.5rem;
            min-width: 300px;
        }

        &--delete {
            position: absolute;
            right: 0.65rem;
            top: 0.65rem;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        .btn {
            margin-left: 0.25rem;
            right: -0.35rem
        }
    }
}