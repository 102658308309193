/* LISTS */

/* UL */

.list {
    &--bullets {
        list-style-type: disc;
        padding: 0 1.5rem;
        margin-bottom: 1rem;
    }

    &--dl {
        display: flex;
        flex-flow: row wrap;
        &__dt {
            font-weight: bold;
            flex-basis: 20%;
        }

        &__dd {
            flex-basis: 80%;
            flex-grow: 1;
        }
    }

    //SbC in cart-review
    &--dd {
        &__listitem {
            display: flex;
            justify-content: space-between;
        }
    }

    &--ranking,
    &--top10,
    &--top20 {
        &__actions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 0.5rem;

            .block-content--book__cart-actions {
                display: flex;
                flex-direction: row;
            }

            .btn--icon {
                width: 1rem;
                height: 1rem;
            }
        }

        &__list {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: flex-start;
        }

        &__listitem {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0.5rem 1.5rem 1rem;
            cursor: pointer;
            img {
                max-width: 7rem;
                border: solid 1px #CCC;
            }
        }
        &__ic--rank {
            position: absolute;
            width: 1rem;
            height: 1rem;
            padding: 0.25rem;
            border-radius: 50%;
            background-color: #FFF;
            border: solid 2px #CCC;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            top: -0.5rem;
            left: -0.5rem;
        }
    }

    &--orders {
        &__header {
            display: flex;
            flex-direction: row;
        }
        
        &__header-item {
            width: 7rem;
        }
        
        &__order-item {
            position: relative;
            border: solid 1px #CCC;
            border-radius: 0.3rem;
            margin-bottom: 1rem;
            padding: 0.5rem 1rem;
            display: flex;
            flex-direction: column;
        }

        &__id {
            display: block;
        }

        &__date {
            display: block;
            width: 7rem;
        }

        &__details {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__button {

        }

        &__descr {
            font-size: $fontSizeReduced;
        }
    }

    &--media-playlist {
        list-style-type: disc;
        margin: 1.5rem 1.5rem 1.5rem 2.5rem;
    }

    &--icons {
        display: flex;
        flex-direction: row;
        margin-bottom: 0.5rem;
        
        li {
            margin: 0.25rem;
        }
    }

    &--yesno {
        display: flex;
        flex-direction: row;
        width: 80%;

        &__listitem {
            flex: 1;
            width: 50%;
            
            input {
                display: none;
            }
            
            &.icon {
                background-size: 1.5rem;
                background-repeat: no-repeat;
                background-position: top left;
                height: auto;

                &--ok {
                    background-image: url('../../images/icons/ic-check-green.svg');
                    background-size: 1.35rem;
                    filter: grayscale(100%);
                    opacity: 0.4;
                    color: #000;
                    &.--selected {
                        filter: none;
                        opacity: 1;
                        color: green;
                    }
                }
                &--clear {
                    background-image: url('../../images/icons/ic-clear-red.svg');
                    filter: grayscale(100%);
                    opacity: 0.4;
                    color: #000;
                    &.--selected {
                        filter: none;
                        opacity: 1;
                        color: red;
                    }
                }
            }
            span {
                margin-left: 1.6rem;
                margin-top: 0.15rem;
                display: block;
                font-size: $fontSizeReduced;
            }
        }
    }
}