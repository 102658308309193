/* NEWSLETTERS */

.newsletters {
    &__list {
        list-style-type: disc;
        padding: 0 0 0 1.5rem;

        li {
            margin-bottom: 1rem;
        }

        &--admin {
            margin: 0.5rem 0;
            li {
                margin-bottom: 0;
            }
        }
    }
    &__listitem {
        //display: block;
        @media only screen and (min-width: (64px*10)) {
            display: flex;
        }

        a {
            //color: $txtColorBase-branded;
            text-decoration: none;
        }
    }
}

.newsletter {
    &__date {
        font-size: $fontSizeReduced;
        display: block;
    }
    &__title {
        display: block;
        color: $txtColorBase-branded;
        font-size: 1rem;

        &.newsletter__pdf {
            font-size: 1rem;
        }
        @media only screen and (min-width: (64px*10)) {
            display: inline-block;
        }
    }
    &__title-container {
        display: flex;
        flex-direction: column;
    }
    &__pdf,
    &__php {
        font-size: 0.8rem;
        color: #000;
    }
    &__sub {
        display: block;
        font-size: $fontSizeReduced;
    }
    &__subtitle {
        position: relative;
        display: block;
        font-size: $fontSizeReduced;
        line-height: 1.35;
        @media only screen and (min-width: (64px*10)) {
            display: inline-block;
            font-size: 1rem;
            min-width: 16rem;
            margin-right: 0.5rem;
        }
        span {
            display: block;
            font-size: $fontSizeReduced;
        }
    }
    &__actions {
        display: flex;
        align-items: center;
        span {
            margin-right: 1rem;
        }
    }
}