/* NAVIGATION SECUNDARY */

.navigation--sb {
    margin: 0;
    font-size: $baseFontSizeReduced;
    display: none;
    height: $barHeight;

    @media only screen and (min-width: (64px*10)) {
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    &__list {
        list-style-type: none;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        height: 100%;
        @include color('txtColorBase-branded');
    }

    &__listitem {
        padding: 0.25rem 0.5rem 0.15rem;;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        @include color('txtColorBase-branded');

        a {
            color: $txtColorBase-inverted;
            text-decoration: none;
        }

        &.--active {
            color: $txtColorBase-branded;
            font-weight: bold;
            border-radius: 5px 5px 0 0;
            @include background-color('bgColor-branded-secundary');

            + .navigation--sb__listitem {
                border: none;
            }
        }
    }
}
