/* PAGING */

.paging-back-wrapper,
.paging-forward-wrapper {
    //z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.3rem;
    opacity: 1;
    width: 1.6rem;
    height: 1.6rem;
    margin: 0 0.25rem;
    cursor: pointer;

    @include background-color('bgColor-branded-secundary');

    &__icon {
        display: block;
        width: 30%;
        height: 30%;
        border-top: solid 3px #FFF;
        border-left: solid 3px #FFF;
    }
}

.paging-back-wrapper {
    cursor: pointer;
    &__icon {
        transform: rotate(-45deg);
        margin-right: -0.2rem;
        
    }

    &.--inactive  {
        background-color: #CCC;
        cursor: initial;
    }
}

.paging-forward-wrapper {
    cursor: pointer;
    &__icon {
        transform: rotate(135deg);
        margin-left: -0.3rem;
    }

    &.--inactive  {
        background-color: #CCC;
        cursor: initial;
    }
}