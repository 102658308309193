/* NAVIGATION SECUNDARY */

.navigation--toggle {
    margin: 0;
    font-size: $baseFontSizeReduced;
    width: 100%;
    position: relative;
    display: flex;
    z-index: 9;

    position: absolute;
    top: 60%;
    transform: translate(0, -50%);

    @media only screen and (min-width: $triggerMobileNav) {
        display: flex;
        position: absolute;
        left: 50%;
        top: 5.7rem;
        transform: translateX(-50%);
        justify-content: center;
    }
    @media only screen and (min-width: $triggerMobileNav) {
        top: 3.25rem;

        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &__list {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        height: 100%;
        margin: auto;
        padding: 0 0.25rem;

        @include color('txtColorBase-branded');
        @media only screen and (min-width: 480px) {
            flex-wrap: nowrap;
        }

        @at-root .block__block-content & {
            width: 100%;
            max-width: 40rem;
        }
    }

    &__listitem {
        position: relative;
        display: flex;
        flex: 1;
        flex-direction: column;
        cursor: pointer;
        //max-width: 7rem;
        margin: 0.125rem 0.15rem;
        padding: 0.3rem 0.25rem 0.4rem;
        justify-content: center;
        align-items: center;
        background-color: $bgColor-branded-secundary;
        border: solid 2px $borderColor-branded;
        border-radius: 5px;
        color: $txtColorBase-branded;
        @media only screen and (min-width: (64px * 10)) {
            max-width: 8.5rem;
            width: 8.5rem;
        }
        
        span {
            text-align: center;
            font-weight: bold;
            font-size: 0.8rem;
            line-height: 1.35;

            &:first-child {
                // font-weight: bold;
                // font-size: 0.8rem;
            }
        }

        &:nth-child(1) {
            background-color: rgba($bgColor-branded-secundary, 0.5);
        }

        &:nth-child(2) {
            background-color: rgba($bgColor-branded-secundary, 0.5);
        }

        &:nth-child(3) {
            background-color: rgba($bgColor-branded-secundary, 0.5);
        }

        &.--active {
            background-color: $bgColor-branded-dark;
            color: $txtColorBase-inverted;
        }

        &:hover {
            background-color: #000;
            color: #FFF;
            border: solid 2px #000;
        }

        @at-root .block__block-content & {
            position: relative;
            margin: -2rem auto auto;
            padding: 0.3rem 0.35rem 0.4rem;
            background-color: $bgColor-branded-secundary-full;

            &:hover {
                background-color: #000;
            }

            &.--active {
                background-color: $bgColor-branded-dark;
            }
        }
    }

    &__listitem--content {
        @at-root .block__block-content & {
            flex: 1;
            justify-content: center;
            position: relative;
            
        }

        &:hover {
            @at-root .block__block-content & {
                .navigation--toggle__listitem {
                    background-color: #000;
                    border-color: #000;;
                    color: #FFF;
                }
                + .navigation--toggle__tooltip {
                    display: block;
                }
            }
        }
    }

    &__tooltip {
        display: none;
        position: absolute;
        left: 0;
        top: 7rem;
        text-align: center;
        margin-top: 2rem;
        width: 70%;
        left: 50%;
        transform: translateX(-50%);
        background-color: #FFF;
        border: solid 1px $borderColor-branded; 
        border-radius: 5px;
        color: #000;
        z-index: 10;

        .dialog-header {
            display: block;
            width: 100%;
            max-width: 100%;
            font-size: 1rem;
            font-weight: bold;
            line-height: 1.2;
            margin: 0.75rem 0 0.5rem;
        }

        .dialog-content {
            width: calc(100% - 2rem);
            margin: 0 0 1rem;
            padding: 0 1rem;
            line-height: 1.35;
        }

        &.--active {
            display: block;
            margin: 0 0.25rem;
        }
    }

    @at-root .block__block-content & {
        position: relative;
        top: auto;

        img {
            height: 7.5rem;
        }
    }
}
