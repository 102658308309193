/* BLOCK PAGINATION */

.block--pagination {
    height: auto;
    min-height: 0;
    width: 100%;
    border-top: solid 1px #CCC;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-top: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--top {
        //width: calc(100% - 6rem);
        //margin-left: 6rem; 
    }

    @media only screen and (min-width: (64px * 8)) {
        flex-direction: row;
    }

    &__actions {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 0.1rem;
    }

    &__inview,
    &__paging,
    &__list {
        display: flex;
        flex-direction: row;
    }

    &__inview {
        span {
            margin: 0.25rem 0.5rem 0.25rem 0;
        }
    }

    &__paging {
        justify-content: flex-end;
        margin-right: -0.25rem;
        span {
            display: block;
            margin: 0.25rem;
        }
    }

    &__listitem {
        margin: 0.25rem;
        &.--active {
            border: 2px solid #666;
            border-radius: 0.3rem;
            //outline-style: auto;
            padding: 0 0.4rem 0 0.4rem;
            margin-top: 2px;
        }
    }
}