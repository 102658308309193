/* MEDIA PLAYERS */

.wrapper-media {
    &__player {
        &--video {
            width: 100%;
            margin: auto;
            > div {
                max-width: 75%;
                margin: auto;
            }

            .block--video {
                width: 75%;
            }
        }
        &--audio {
            
        }
    }

    &__playlist {

    }
}

.block--video {
    position: relative;
    &__player {

    }

    &__description {
        position: relative;
        text-align: left;
    }
    
    &__actions {
        position: absolute;
        right: 0;
        top: 0.2rem;
        display: flex;

        @at-root .dialog-content & {
            position: relative;
        }
    }
    
    &__cc {
        border: solid 1px #AAA;
        border-radius: 0.2rem;
        width: 1.45rem;
        height: 1.25rem;
        line-height: 1.25rem;
        padding-bottom: 0.1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        &.--active {
            background-color: #AAA;
            color: #FFF; 
        }
    }
    
    &__enlarge {
        width: 1.75rem;
        height: 1.5rem;
        background-color: #FFF;
        //background-image: url('../../../images/icons/ic-enlarge-scr.png');
        background-image: url('../../../images/icons/ic-view-popup.svg');
        background-size: cover; 
        background-repeat: no-repeat;
        text-indent: -999rem;
        margin-left: 0.5rem;
        margin-top: -0.15rem;
        opacity: 0.5;
        &:hover {
            opacity: 1;
        }
    }
}