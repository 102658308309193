/* CONTENT */

.content {
    &--empty {
        text-align: center;
        margin: 1rem 0;
    }

    &--empty-segment {
        text-align: center;
    }

    &__salutation {
        display: block;
        padding-top: 2rem;
        font-size: $fontSizeReduced;
        text-align: center;
        width: 70%;
        margin: auto;
    }

    &__examples {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        //justify-content: center;
        padding: 1rem;

        div {
            margin: 0 1rem 0 0;
        }

        .example {
            &--logo {
                img {
                    width: 75px;
                }
            }
            &--logo-flat {
                padding: 0.7rem 0.5rem 0.3rem;
                border: solid 1px #CCC;
                border-radius: 0.3rem;
                img {
                    width: 150px;
                }
            }
            &--logo-cover {
                
            }
        }
    }

    &__tooltip {
        position: absolute;
        top: -0.5rem;
        right: -1rem;
        width: 0.75rem;
        height: 0.75rem;
        border: solid 2px green;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-style: italic;
        font-family: 'Times New Roman', Times, serif;
        font-size: 0.75rem;
        color: mediumseagreen;
        text-align: left;

        @at-root .block--select-segment & {
            top: -0.5rem;
            right: 1rem;
            width: 1.75rem;
            height: 1.75rem;
        }
    }
}

.content-tabs {
    &__list {
        display: flex;
        flex-direction: row;
    }

    &__listitem {
        flex: 1;
        padding: 0.5rem 1rem;
        border: solid 1px #DDD;
        border-bottom: solid 1px #CCC;
        border-radius: 0.3rem 0.3rem 0 0;
        cursor: pointer;

        &--count {
            margin-left: 0.4rem;
        }

        &.--active {
            font-weight: bold;
            border: solid 1px #CCC;
            border-bottom: none;
            @include color('txtColorContent-branded');
        }
    }
}

.content-segment {
    &__content {
        span {
            display: block;

            &.content__title-book {
                display: inline;
                font-style: italic;
            }
        }

        dl {
            display: flex;
            flex-flow: row wrap;

            dt {
                width: 30%;
            }

            dd {
                width: 70%;
                margin: 0 0 1rem;
            }
        }
    }
}

.amount {
    white-space: nowrap;
    margin-left: 0.5rem;
    &:before {
        content: '\20AC';
        display: inline-block;
        margin-right: 0.25rem;
    }

    &--inline {
        margin-left: 0.1rem;
        margin-right: 0.1rem;;
        font-weight: bold;
        &:before {
            margin-right: 0.125rem;
        }
    }
}

.icon {
    background-size: 100% 100%;
    background: left center no-repeat;
    width: 1rem;
    height: 1rem;
    display: block;
    &--delete {
        background-image: url('../../images/icons/cross-black.svg');
    }
}

.--alert {
    color: red;
}

.--link {
    cursor: pointer;
}

.--nolink {
    cursor: default;
    text-decoration: none;
}

.--link-clean {
    cursor: pointer;
    text-decoration: none;
}

.--link-inline {
    color: $color-dark-sb;
    font-weight: bold;
    margin: 0 0.35rem;

    &:after {
        content: "";
        display: inline-block;
        position: relative;
        right: -0.35rem;
        top: 0.05rem;
        width: 0;
        height: 0;
        border-top: 0.3rem solid transparent;
        border-bottom: 0.3rem solid transparent;
        border-left: 0.3rem solid $color-dark-sb;
    }
}


.--lang {
    text-transform: capitalize;
}

.link {
    &--pdf {
        text-decoration: none;
        font-size: 0.8rem;
        color: #000;
        text-align: center;
    }

    &--read-more,
    &--back {
        font-size: $baseFontSizeReduced;
        @include color('txtColorBase-branded');
        display: block;
        text-decoration: none;
        cursor: pointer;
    }
    
    &--read-more {
        &:after {
            content: "";
            position: relative;
            margin-left: 8px;
            width: 0; 
            height: 0; 
            @include border-color('arrowColor-branded');
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent; 
            border-left: 7px solid;
            display: inline-block;
        }

        &.actionShowMore {
            &:after {
                top: 0.25rem;
                @include border-color('arrowColor-branded');
                border-right: 5px solid transparent;
                border-left: 5px solid transparent; 
                border-top: 7px solid;
            }
        }

        &.actionShowMore--up {
            &:after {
                @include border-color('arrowColor-branded');
                border-left: 5px solid transparent;
                border-right: 5px solid transparent; 
                border-bottom: 7px solid;
                top: -0.05rem;
            }
        }
    }

    &--back {
        &:before {
            content: "";
            position: relative;
            margin-right: 8px;
            width: 0; 
            height: 0; 
            @include border-color('arrowColor-branded');
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent; 
            border-right: 7px solid;
            display: inline-block;
        }
    }

    &--subscribe {
        display: block;
        font-size: $baseFontSizeReduced;
        text-decoration: none;

        &-subtitle {
            margin-top: -0.75rem;
            line-height: 1rem;
        }
    }

    &--hd-subscribe {
        display: block;
        margin-top: 1rem;
        font-size: $baseFontSizeReduced;
        font-weight: bold;
    }
}

.actionShowMore {
    font-size: $fontSizeReduced;
    margin-top: 0.5rem;
    cursor: pointer;

    clear: both;
    display: block;
    width: 100%;
    text-align: center;
}

.cover {
    &--previews {
        @include foldOutListFixed(3, 5rem);
        .actionShowMore {
            font-size: $fontSizeReduced;
            margin-top: 0.5rem;
        }
        display: flex;
        flex-flow: row wrap;
        margin: 0 -0.25rem;

        &__cover {
            width: 5rem;
            padding: 0.25rem;
            border: solid 1px #CCC;
            margin: 0.25rem;
            img {
                width: 100%;
            }
        }
    }
}

