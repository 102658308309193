/* SWIPER */

.content-wrapper__sidebar {
    .swiper-wrapper,
    .swiper-container {
        z-index: 0;
    }
}

.swiper-container {
    .swiper-button-next, 
    .swiper-button-prev {
        border: solid 2px #CCC;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        color: #CCC;
        top: 40%;
        &:after {
            font-size: 1rem;
        }
    }

    .swiper-button-prev {
        left: 0;
        right: auto;
        
        &:after {
            margin-right: 0.15rem;
        }
    }

    .swiper-button-next {
        right: 0;
        left: auto;
        
        &:after {
            margin-left: 0.15rem;
        }
    }

    .swiper-pagination {
        display: none;
        position: relative;
    }

    .swiper-pagination-bullet-active {
        background-color: $bgColor-branded-dark;
    }
}