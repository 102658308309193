/* ARROWS */

@mixin arrow($color, $direction, $width) {
    border: solid $color;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin-top: 50%;
    position: relative;
    width: $width;
    height: $width;

    @if $direction == up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    } @else if $direction == right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    } @else if $direction == down {
        transform: translateY(-50%) rotate(45deg);
        -webkit-transform: translateY(-50%) rotate(45deg);
    } @else if $direction == left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    } @else {
        @error "Unknown direction #{$direction}.";
    }
}

@mixin arrowSolid ($color, $direction, $width) {   
    width: 0;
    height: 0;
    
    @if $direction == top {   
        border-left: $width solid transparent;
        border-right: $width solid transparent;
        border-bottom: $width solid $color;
    } @else if $direction == right {   
        border-top: $width solid transparent;   
        border-bottom: $width solid transparent;   
        border-left: $width solid $color; 
    } @else if $direction == down {   
        border-left: $width solid transparent;   
        border-right: $width solid transparent;   
        border-top: $width solid $color; 
    } @else {   
        border-top: $width solid transparent;   
        border-bottom: $width solid transparent;   
        border-right: $width solid $color; 
    }
}