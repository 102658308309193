/* NAVIGATION TOOLS */

.navigation--tools {
    margin-top: 1.6rem;
    right: 0;
    padding: 1rem;
    z-index: 100;
    
    @media only screen and (min-width: $triggerMobileNav) {
        margin-top: 0.7rem;
    }

    &__list {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__listitem {
        position: relative;
        background-size: 100% 100%;
        background: left center no-repeat;
        height: 1.5rem;
        display: flex;
        align-items: center;
        margin-left: 1.5vw;
        cursor: pointer;
        font-weight: bold;
        @include color('txtColorBase-branded');
        @media only screen and (min-width: (64px*7)) {
            margin-left: 1rem;
        }
        
        &--wishlist {
            padding-left: 1.75rem;
            background-image: url('#{$imagesDir}/icons/wishlist-black.svg');
        }
        &--cart {
            padding-left: 1.75rem;
            background-image: url('#{$imagesDir}/icons/cart-black.svg');
        }
        &--profile {
            padding-left: 1.75rem;
            background-image: url('#{$imagesDir}/icons/ic-profile-black.png');
            background-size: 1.1rem;
            background-position: left 0.25rem center;
            @media only screen and (min-width: (64px*7)) {
                display: flex;
            }
        }
        &--login,
        &--logout {
            padding-left: 1.75rem;
            background-image: url('#{$imagesDir}/icons/login-black.svg');
        }
        &--mobile-nav-trigger {
            display: block;
            top: 1px;
            width: 1.3rem;
            background-image: url('#{$imagesDir}/icons/ic-hamburger-menu.svg');
            @media only screen and (min-width: $triggerMobileNav) {
                display: none;
            }
        }
    }

    &__counter {
        position: absolute;
        left: 1rem;
        top: -0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        @include background-color('bgColor-branded-dark');
        span {
            margin-bottom: 0.1rem;
            font-size: 0.7rem;
            color: $txtColorBase-inverted;
        }
    }
    
    &__tool {
        text-decoration: none;
        font-size: 0.8rem;
        display: none;
        @media only screen and (min-width: (64px*11)) {
            display: block;
        }
    }       
}