/* QUOTES */

.quote {
    display: none;
    @media only screen and (min-width: (64px*12)) {
       display: block;
    }
    &__title {
        position: relative;
        font-family: $brandfont;
        font-size: 1.2rem;
        line-height: 1.4rem;
        text-align: center;

        &:before,
        &:after {
            content: '"';
            font-size: 2.2rem;
            font-style: italic;
            margin: 0 0.35rem 0 0;
        }
        
        &:before {
            left: -0.25rem;
            top: 0.4rem;
            display: inline-block;
            position: relative;
            line-height: 0.75;
        }

        &:after {
            position: absolute;
            bottom: 0;
        }
    }

    &__author {
        font-style: italic;
        font-size: 1rem;
        text-align: center;
        margin-top: 1rem;
        @include color('txtColorBase-branded');

        &:before {
            content: "\2014";
            position: relative;
            display: inline-block;
            top: -2px;
            font-size: $fontSizeReduced;
        }
    }
}