/* Google Translate Widget inline
    Google translation
    SbC hide Google Translate-interface 
*/

.goog-te-banner-frame, 
.goog-te-banner-frame.skiptranslate,
.goog-te-menu-frame, 
.goog-te-gadget-icon {
    display: none !important;
}

.goog-tooltip,
.goog-tooltip:hover {
    display: none !important;
}

.goog-logo-link {
    display: none !important;
}

iframe[style*="z-index: 1000000"] {
    display: none !important;
}

body {
    top: 0 !important;
}

.skiptranslate {
    iframe {
        display: none;
    }
}

/* Hide the tooltip that shows original text */
.VIpgJd-ZVi9od-l4eHX-hSRGPd,
.VIpgJd-suEOdc.VIpgJd-yAWNEb-L7lbkb {
    display: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
}

#goog-gt-tt.VIpgJd-suEOdc.VIpgJd-yAWNEb-L7lbk:hover {
    border: solid 1px red;
    display: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
}

/* Remove underline (text decoration) when hovering over translated text */
span.goog-te-gms-text, span.goog-text-highlight {
    text-decoration: none !important;
    border-bottom: none !important;
    all: unset !important; /* Removes all default styles */
}

/* Prevent hover effects on translated text */
.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q {
    border: none !important;
    background: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

/* Hide the hover tooltip that shows the original text */
.VIpgJd-ZVi9od-l4eHX-hSRGPd,
.VIpgJd-suEOdc.VIpgJd-yAWNEb-L7lbkb {
    display: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
}

/* Hide google spinner */
.VIpgJd-ZVi9od-aZ2wEe-wOHMyf.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
    display: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
}