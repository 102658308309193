/* TABLES */
.table {
    &--financial {
        display: table;
        margin: 0;
        padding-bottom: 1rem;
        margin-bottom: 0.75rem;
        width: 100%;
        font-size: 0.8rem;

        &__header {
            display: none;
            font-weight: bold;
            @media only screen and (min-width: (64px*12)) {
                display: table-row;
                background-color: #EEE;
            }
            .table--financial__cell--date {
                width: 100px;
            }

            &:first-child {
                div {
                    border-bottom: solid 1px #000;
                }
            }
        }
   
        &__row {
            display: table-row;
            position: relative;
            width: 100%;
            border-top: solid 1px $borderColorBase;

            &--discount {
                display: none;
                &.--has-discount {
                    display: block;
                }
            }

            &--toggle {
                height: 2rem;

                div {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }

        &__cell {
            display: table-cell;
            padding: 0.25rem;
            
            &--details-wrapper {
                display: flex;
                flex-direction: column;
                @media only screen and (min-width: (64px*12)) {
                    flex-direction: row;
                }
            }

            &--sku {
                display: block;
                @media only screen and (min-width: (64px*14)) {
                    display: table-cell;
                }
            }
            &--title {
                display: block;
                @media only screen and (min-width: (64px*14)) {
                    display: table-cell;
                }
            }
            &--amount {
                display: table-cell;
                text-align: right;
            }
            &--full {
                display: block;
                width: 300%;
                padding: 0.25rem;
            }
        }

        &__totals {
            display: table-row;
            font-weight: bold;

            &--top {
                border-top: solid 2px #DDD;
                margin-top: 2rem;
                .table--financial__cell {
                    padding-top: 2rem;
                }
            }

            .table--financial__cell--label {

            }
        }
    }
}