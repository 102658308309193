/* SHOPPING CART */

.cart {
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;

    &__actions {
        display: flex;
        flex-direction: row wrap;
        justify-content: flex-end;
        margin: 1rem -0.25rem;
    }

    &__remark {
        margin: 2rem 0 1rem;
    }

    .products {
        &-table {
            display: flex;
            flex-direction: column;
            margin: 0 -0.5rem 0.75rem;
            padding-bottom: 1rem;
            width: calc(100% + 1rem);
            @media only screen and (min-width: (64px*14)) {
                display: table;
            }

            &__notification {
                display: flex;
                justify-content: center;
                align-items: center;
                border: solid 1px #CCC;
                padding: 0.5rem 0.5rem 0.75rem;
                width: 75%;
                margin: -1rem auto 1rem;;
               
                span {
                    color: $txtColorBase-positive;
                    display: block;
                    font-size: $fontSizeReduced;
                    text-align: center;
                }
            }

            &__header {
                display: none;
                @media only screen and (min-width: (64px*12)) {
                    //display: table-row;
                }
            }
       
            &__row {
                position: relative;
                display: flex;
                flex-direction: column;
                border: solid 1px $borderColorBase;
                margin-bottom: 1rem;

                &--details {
                    display: flex;
                    flex-direction: column;
                    @media only screen and (min-width: (64px*8)) {
                        flex-direction: row;
                    }
                }

                &--discount {
                    display: none;
                    &.--has-discount {
                        display: block;
                        padding: 0 0.5rem 0.5rem;
                        font-size: $fontSizeReduced;
                    }
                }
            }

            &__cell {
                position: relative;
                display: table-cell;
                padding: 0.25rem;
                &--details-wrapper {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    @media only screen and (min-width: (64px*12)) {
                        flex-direction: row;
                    }
                    @media only screen and (min-width: (64px*14)) {
                        //display: table-cell;
                        //flex-direction: column;
                        display: flex;
                        flex-direction: row;
                        min-height: 5rem;
                        align-items: center;
                    }

                    &-shipping {
                        flex-direction: column;
                        align-items: flex-start;
                        min-height: auto;
                    }

                    &-total {
                        min-height: auto;
                    }
                }
                &--details-content {
                    display: flex;
                    align-items: center;
                }
                &--price-wrapper {
                    flex: 1;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    @media only screen and (min-width: (64px*14)) {
                        //margin-right: -0.5rem;
                    }
                    .products-table__cell {
                        min-width: 33.3%;
                        &--icon {
                            width: 1rem;
                            min-width: 0;
                            position: absolute;
                            right: 0;
                            top: 0;
                            @media only screen and (min-width: (64px*14)) {
                                //position: relative;
                                //margin-right: -0.5rem;
                            }
                        }
                    }
                }
                &--sku {
                    //display: flex;
                    img {
                        display: block;
                        width: 50px;
                        height: auto;
                    }
                }
                &--title {
                    display: block;
                    font-weight: bold;
                    @media only screen and (min-width: (64px*14)) {
                        display: table-cell;
                    }
                }
                &--title-subtitle {
                    display: block;
                    padding-top: 0;
                    font-size: $fontSizeReduced;
                    margin-top: -0.5rem;
                }
                &--subtitle {
                    display: block;
                    font-size: $fontSizeReduced;
                    
                }
                &--amount {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                }
                &--count {
                    text-align: right;
                }
                &--icon {
                    cursor: pointer;
                    vertical-align: middle;
                    width: 1rem;
                    min-width: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
                &--descr {
                    display: block;
                    margin-top: -0.5rem;
                    font-size: $fontSizeReduced;
                    
                }
                &.--small {
                    font-size: $fontSizeReduced;
                }
            }
        }
    }

    &__text {
        &--descr {
            font-size: $fontSizeReduced;
        }

        &--descr-shipping {
            display: block;
            margin-top: -0.5rem;
            font-size: $fontSizeReduced;
            &:before {
                position: absolute;
                margin-left: -0.6rem;
                content: '*';
                display: block;
            }
        }
    }

    .totals {
        &-table {
            display: table;
            align-self: flex-end;
            border-top: solid 3px #000;
            padding-top: 0.5rem;
            margin: 0 -0.25rem 1rem;

            &__row {
                display: table-row;
                
                &--totals {
                    .products-table__cell {                      
                        &--totals {
                            width: 100%;
                            border-top: solid 1px #CCC;
                            margin: 0.5rem 0 0;
                            padding-top: 0.75rem;
                        }
                        &--amount {
                            .products-table__cell--totals {
                                display: flex;
                                justify-content: flex-end;
                            }
                        }
                    }
                }
            }
        }
    }
}

.cart-data {
    &__section {
        ul + p {
            margin-top: 1rem;
        }
    }

    &__section--cart {
        ul + p {
            margin-top: 1rem;
        }
    }

    &__table {
        display: table;
        width: calc(100% + 1rem);
        margin: 0 -0.5rem;
        border: solid 1px #CCC;
        .products-table__row {
            border: none;
            margin-bottom: 0;
            &:last-child {
                margin-top: 1rem;
                border-top: solid 2px #CCC;
            }
        }
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        margin: 0.5rem -0.25rem;
    }

    &__subtitle {
        margin-top: -1rem;
        //margin-bottom: 1.5rem;
        display: block;
        + .form-wrapper {
            margin-top: 1.5rem;
        }

        + .cart-data__subtitle {
            margin-top: 0;
        }
    }

    &__feedback {
        font-size: $fontSizeReduced;
        margin-top: 1rem;
    }
}