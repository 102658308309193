/* BOOKS */

.book {
    &__header {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;
    }

    &__content {
        display: flex;
        flex-flow: row wrap;
        &--column {
            flex: 1;
            &:last-child {
                flex: 2;
            }
        }
        &--details {
            flex-direction: column;
            margin-bottom: 2rem;
        }
    }

    &__preview {
        flex: 1;
        padding: 0.25rem 0.5rem 0.5rem 0;
        img {
            width: 100%;
        }
    }

    &__description--short {
        flex: 2;
    }

    &__cart {
        width: 70%;
        float: right;
        margin-top: 2rem;
    }

    &__read-more {
        font-size: $baseFontSizeReduced;
        &:after {
            content: "";
            position: relative;
            margin-left: 5px;
            top: 1px;
            width: 0; 
            height: 0; 
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent; 
            border-left: 7px solid $bgColor-branded-dark;
            display: inline-block;
        }
    }

    &__read-more--down {
        font-size: $baseFontSizeReduced;
        // position: relative;
        // top: -2rem;
        //position: absolute;
        transform: translateY(-2rem);
        &:after {
            content: "";
            position: relative;
            margin-left: 5px;
            top: 1px;
            width: 0; 
            height: 0; 
            border-right: 5px solid transparent;
            border-left: 5px solid transparent; 
            border-top: 7px solid $bgColor-branded-dark;
            display: inline-block;
        }
    }

    &__reviews--highlights {
        width: 100%;
        min-height: 150px;
        border: solid 1px #CCC;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem 0;
    }

    &__category {
        text-transform: uppercase;
        font-size: 0.85rem;
    }

    &__title {
        font-size: 2rem;
        text-align: center;
        line-height: 1;
        margin-top: 0.2rem;
        margin-bottom: 0.5rem;

    }

    &__subtitle {
        width: 70%;
        font-size: 1.15rem;
        line-height: 1.15;
        margin-bottom: 1rem;
        text-align: center;
    }

    &__author {
        font-size: 0.85rem;
        color: $txtColorBase-branded;
        text-decoration: underline;
        cursor: pointer;
    }

    &__sticker {
        position: absolute;
        right: 0;
        top: -25%;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        background-color: #666;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFF;
        transform: rotate(15deg);
    }

    &__specs {
        dl {
            max-width: 400px;
            dt {
                font-weight: bold;
                font-size: 0.75rem;
            }

            dd {
                margin: 0 0 0.5rem;
                padding: 0;
            }
        }
    }

    &__cart {
        .cart__price {
            display: flex;
            justify-content: flex-end;
            font-size: 1.5rem;
            // &:before {
            //     content: "€";
            //     display: block;
            //     margin-right: 0.5rem;
            // }
            span {
                display: flex;
                // span {
                //     font-size: $baseFontSizeReduced;
                //     margin-top: 1rem;
                //     margin-left: 0.15rem;
                // }
            }
        }

        .cart__actions {
            display: flex;
            flex-flow: row wrap;
            margin: 0 -0.25rem;
        }
    }

    &__social {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1.5rem 0 2rem 0;
        h3 {
            margin: 0.25rem;
        }
    }

    &__tabs {
        display: block;
    }
}
    
.book-specs {
    &__wrapper {
        display: flex;
        flex-flow: row wrap;
    }

    &__column {
        flex: 1;
        min-width: 200px;

        dl {
            margin: 0;
        }
    }
}