/* BLOCKS NAVIGATION */

.blocks {
    &__nav {
        display: flex;
        flex-direction: column;
        //justify-content: space-between;
        //align-items: flex-end;
        margin-bottom: 0.5rem;
        padding-bottom: 0.5rem;
        border-bottom: solid 1px #CCC;

        &--top {
            //margin-left: 6rem;
        }

        + .block--pagination {
            border-top: none;
            margin-top: 0;
            padding-top: 0;
        }

        + .block--stock-priority {
            border-top: none;
            margin-top: 0;
            padding-top: 0;
        }       
    }

    &__nav-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &-nav {
        &__list {
            // display: flex;
            // flex-flow: row;
            // flex: 1;
            margin: 0 -0.25rem;

            &.--column {
                flex-direction: column;
            }

            &:last-child {
                justify-content: flex-end;
            }

            &--sort {
                display: flex;
                li:first-child {
                    display: none;
                    margin: 0 0.5rem 0 0.35rem;
                    @media only screen and (min-width: (64px*6)) {
                        display: block;
                    }
                }
            }

            &--unsort {
                display: block;
                margin-left: 0.35rem;
                margin-top: -0.25rem;
                font-size: $fontSizeReduced;
                text-decoration: underline;
            }

            &--view {
                display: flex;
            }
        }

        &__listitem {
            padding: 0.25rem;
            opacity: 0.15;
            background-size: 100% 100%;
            background: center center no-repeat;
            text-indent: -999rem;
            width: 1.5rem;
            height: 1.5rem;
            cursor: pointer;

            &--summaries {
                background-image: url('#{$imagesDir}/icons/list-black.svg');
            }
            &--covers {
                background-image: url('#{$imagesDir}/icons/grid-black.svg');
            }
            &--list {
                background-image: url('#{$imagesDir}/icons/list-text-black.svg');
            }
            &--carousel {
                background-image: url('#{$imagesDir}/icons/carousel-black.svg');
            }

            &.--active {
                opacity: 1;
            }
        }

        &__sortitem {
            margin: 0 0.5rem;
            opacity: 0.5;
            cursor: pointer;
            &.--active {
                opacity: 1;
                font-weight: bold;
            }

            &.--down,
            &.--up {
                padding-right: 10px;
                &:after {
                    content: ' ';
                    position: relative;
                    left: 0.5rem;
                    border: 0.4rem solid transparent;
                }
            }

            &.--down:after {
                top: 1rem;
                border-top-color: silver;
            }

            &.--up:after {
                bottom: 15px;
                border-bottom-color: silver;
            }
        }
    }

    .block--stock-priority {
        height: auto;
        min-height: 0;
        margin: -1rem 0 0;

        .form-group--checkbox {
            justify-content: flex-end;
            left: 1rem;
        }
    }
}