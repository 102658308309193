/* AUTHOR INFO*/

.block--popular {
    //margin-top: 3.5rem;
    padding: 1rem 0;

    &__block-title {
        font-family: $brandfont;
        margin-top: 0.4rem;
        text-align: center;
        margin-bottom: 0.5rem;
        border-bottom: solid 1px #CCC;

        &--main {
            display: block;
            font-size: 1rem;
            font-weight: bold;
            line-height: 1.3;
            margin-bottom: 1rem;
        }
    }
}