/* MANUALS */

.dialog--content-manual {
    .dialog-header {
        display: none;
    }
}

.content-manual {
    h2 {
        margin-top: 0.75rem;
    }

    img.content-manual__code-result {
        border: solid 1px #CCC;
        width: 150px;
    }

    &__alert { 
        max-width: 400px;
        margin: 0.5rem auto 1rem;
        border: solid 1px red;
        color: red;
        padding: 0.5rem 0.5rem 0.75rem;
    }

    &__code-wrapper {
        border: solid 1px #CCC;
        display: block;
        margin: 0 0 1rem;
        padding: 0;
    }

    &__code {
        font-size: $fontSizeReduced;
        margin: 0;
        padding: 0.5rem;
    }

    &__subtitle {
        margin-bottom: 0.5rem;
    }

    &__examples {
       ul {
           display: flex;
           flex-wrap: wrap;
           margin: 0 -1rem;
       }

       .example--logo {
           border: solid 1px #CCC;
           margin: 1rem;
           padding: 0.25rem;

           img {
               display: block;
           }
       }
    }
}