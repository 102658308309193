/* CART CONFIRMATION */

.cart-confirmation {
    &--completed {
        text-align: center;
        margin-top: 2rem;
        h2 {
            font-size: 1.25rem;
            margin: 2rem 0 1rem;
        }

        .cart__actions {
            margin: 2rem 0 3rem;; 
        }

        .block {
            margin-bottom: 1rem;

            img { 
                width: 8rem;
                display: block;
                margin: 1rem auto;
                border: solid 1px #CCC;
            }

            .book-tools__tool-link {
                display: flex;
                &--pdf {
                    margin: 0;
                }
                a {
                    width: 100%;
                }
            }

            &--view-video,
            &--view-pdf {
                padding: 1rem 0;
            }

            &--view-pdf {
                display: flex;
                flex-direction: column;

                h2 {
                    font-size: 1rem;
                }
                
                img {
                    width: 10rem;
                }
            }
        }
    }
}