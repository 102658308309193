/* NAVIGATION MAIN */

$breakLi6: 1200px;
$breakLi5: 1000px;
$breakLi4: 800px;
$breakLi3: 600px;
$breakLi2: 400px;

.navigation--main {
    position: relative;
    margin: 0;
    font-size: $baseFontSizeReduced;
    height: $barHeight;
    display: none;

    @media only screen and (min-width: (64px*10)) {
        display: block;
    }

    &__list {
        position: relative;
        z-index: 100;
        margin: 0 0 0 6rem;
        list-style-type: none;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        height: 100%;
        @include color('txtColorBase-branded');
    }

    &__listitem {
        display: flex;
        flex-direction: row;
        padding: 0.25rem 0.5rem 0.15rem;
        border-left: solid 1px #AAA;
        cursor: pointer;

        &:first-child {
            border-left: none;
        }

        &:nth-child(3) {

        }
        
        &--clean {
            cursor: default;
            color: #000;
            font-weight: bold;
        }
        // &:nth-child(2) {
        //     display: none;
        //     @media only screen and (min-width: (#{$breakLi2})) {
        //         display: block;
        //     }
        // }
        // &:nth-child(3) {
        //     display: none;
        //     @media only screen and (min-width: (#{$breakLi3})) {
        //         display: block;
        //     }
        // }
        // &:nth-child(4) {
        //     display: none;
        //     @media only screen and (min-width: (#{$breakLi4})) {
        //         display: block;
        //     }
        // }
        // &:nth-child(5) {
        //     display: none;
        //     @media only screen and (min-width: (#{$breakLi5})) {
        //         display: block;
        //     }
        // }
        // &:nth-child(6) {
        //     margin-left: auto;
        //     @media only screen and (min-width: (64px*8)) {
        //         margin-right: 1rem;
        //     }
        //     @media only screen and (min-width: (#{$breakLi5})) {
        //         display: none;
        //     }
        // }

        &.--active {
            background-color: $bgColor-branded-secundary;
            color: $txtColorBase-branded;
            font-weight: bold;
            border-radius: 5px 5px 0 0;

            + .navigation--main__listitem {
                border: none;
            }
        }

        a {
            color: $txtColorBase-inverted;
            text-decoration: none;
        }

        .icon--after {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 0.5rem;
        }

        .arrow--down {
            @include arrow(#FFF, down, 0.2rem);
        }
    }

    &__dropdown {
        display: none;
        background-color: #FFF;
        position: absolute;
        top: 2rem;
        right: 0;
                
        &.--active {
            display: block;
            z-index: 999;
        }
        
        li {
            color: #000;
            padding: 0.2rem 0.5rem;
            &:nth-child(1) {
                display: block;
                @media only screen and (min-width: (#{$breakLi2})) {
                    display: none;
                }
            }
            &:nth-child(2) {
                display: block;
                @media only screen and (min-width: (#{$breakLi3})) {
                    display: none;
                }
            }
            &:nth-child(3) {
                display: block;
                @media only screen and (min-width: (#{$breakLi4})) {
                    display: none;
                }
            }
            &:nth-child(4) {
                display: block;
                @media only screen and (min-width: (#{$breakLi5})) {
                    display: none;
                }
            }
        }
    }
  
}