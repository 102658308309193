/* TYPOGRAPHY */

.content {
    &__textlink {
        text-decoration: underline;
        color: $txtColorBase-branded;
        cursor: pointer;
    }
}

h1 {
    line-height: 1.1;
}

h2 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

p {
    margin-top: 0;
}

a {
    //color: $txtColorBase-branded;
    @include color('txtColor-link');
}