// BOOK PROMO

.block__books {
    &--promo {
        background-color: $bgColor-base-middle;
        min-height: 10rem;
        margin: 0 auto;
        padding: 0.25rem 1rem 1.25rem;
        max-width: 35rem;

        .block__block-title--main {
            color: #000;
            font-size: 1rem;
            font-weight: bold;
            max-width: 60%;
            margin: 0 auto 1.25rem;
        }

        .blocks__list--promos {
            grid-template-columns: 1fr;

            @media only screen and (min-width: (64px*8)) {
                flex-direction: row;
                grid-template-columns: 1fr 1fr 1fr;
            }

            .block__books--promo-book {
                margin: 1rem;

                @media only screen and (min-width: (64px*8)) {
                    margin: 0;
                }

                .cover__wrapper {
                    max-width: 7rem;
                }

                .block-content--book__cover-info {
                    display: none;
                }
            }

        }
    }
}