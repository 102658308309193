/* NAVIGATION SECUNDARY */

.navigation--secundary {
    position: relative;
    margin: 0;
    font-size: $baseFontSizeReduced;
    display: none;
    height: $barHeight;
    z-index: 100;

    @media only screen and (min-width: (64px*8)) {
        display: block;
    }

    &__list {
        list-style-type: none;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        height: 100%;
        @include color('txtColorBase-branded');
    }

    &__listitem {
        padding: 0.25rem 0.5rem 0.15rem;;
        display: flex;
        flex-direction: row;
        border-left: solid 1px #AAA;
        &:first-child {
            border-left: none;
        }
        cursor: pointer;

        a {
            color: $txtColorBase-inverted;
            text-decoration: none;
        }

        &.--active {
            @include background-color('bgColor-branded-secundary');
            @include color('txtColorBase-inverted');
            font-weight: bold;
            border-radius: 5px 5px 0 0;

            + .navigation--secundary__listitem {
                border: none;
            }
        }
    }
}
