/* BLOCKS LISTS */

.blocks__list {
    display: flex;
    flex-flow: row wrap;

    &--actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 1rem;
    }

    &--action {
        display: block;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0.25rem;
        span {
            margin-bottom: 0.2rem;
        }
    }

    &--action-icons {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        margin: 0.5rem -0.25rem;
        padding: 0;

        a {
            text-decoration: none;
        }

        span {
            margin-right: 0.25rem;
            font-size: $baseFontSizeReduced;
            &:after {
                display: none;
            }
        }

        .paging-forward-wrapper {
            &.--next {
                width: 1.2rem;
                height: 1.2rem;
                background-color: transparent;
                border: solid 2px $borderColor-branded;

                .paging-forward-wrapper__icon {
                    display: block;
                    width: 30%;
                    height: 30%;
                    border-top: solid 3px $borderColor-branded;
                    border-left: solid 3px $borderColor-branded;
                }
            }

            &.--down {
                width: 1.2rem;
                height: 1.2rem;
                background-color: transparent;
                border: solid 2px $borderColor-branded;

                .paging-forward-wrapper__icon {
                    display: block;
                    width: 30%;
                    height: 30%;
                    border-right: solid 3px $borderColor-branded;
                    border-top: solid 3px $borderColor-branded;
                    margin-left: 0.1rem;
                    margin-top: -0.2rem;
                }
            }
        }
    }

    &--covers {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));

        &-foldout {
            @include foldOutListCovers(1);
        }

        &-foldout3 {
            @include foldOutListCovers(3);
        }

        .block--book {
            position: relative;
            display: flex;
            min-width: -webkit-fill-available;
            min-width: -moz-available;;
        }

        .block--read-more {
            display: flex;
            align-items: center;
            justify-content: center;

            .link--read-more {
                margin: 0.5rem;
            }
        }

        .block-content {
            width: 100%;
            height: 100%;
        
            &--book__price {
                position: absolute;
                bottom: 0;
                width: 100%;
            }

            &--book__cart-actions {
                flex-wrap: nowrap;
                margin-left: 0.5rem;
            }
        }

        .btn--icon {
            width: 1rem;
            height: 1rem;
            margin: 0 0.1rem;
        }

        .block.--elevated {
            background-color: rgba($bgColor-base-dark, 0.7);

            &:before {
                content: attr(data-content);
                position: absolute;
                display: block;
                background-color: rgba($bgColor-base-dark, 0.7);
                margin: -1rem 0 1rem;
                font-size: $baseFontSizeReduced;
                font-weight: bold;
                text-transform: uppercase;
                text-align: center;
                border-bottom: solid 1px #AAA;
                z-index: 9;
                width: 100%;
            }
        }
    }

    &--summaries {
        .block-content--book__cart-actions {
            flex-wrap: wrap;
        }

        &-foldout {
            @include foldOutListSummaries(1);
        }

        &-foldout3 {
            @include foldOutListSummaries(3);
        }

        .block.--elevated {
            background-color: rgba($bgColor-base-dark, 0.3);
            margin: 0 -0.5rem 0.5rem;
            padding: 1rem 0.5rem;

            &:before {
                content: attr(data-content);
                display: block;
                margin: -1rem 0 1rem;
                font-size: $baseFontSizeReduced;
                font-weight: bold;
                text-transform: uppercase;
                text-align: center;
                border-bottom: solid 1px #AAA;
            }
        }
    }

    &--list {
        .block--book {
            padding: 1rem 0;
        }
        .block-content--book__cart-actions {
            flex-wrap: nowrap;
            margin-left: 0.5rem;
        }

        .btn--icon {
            width: 1rem;
            height: 1rem;
            margin: 0 0.1rem;
        }

        .block.--elevated {
            background-color: rgba($bgColor-base-dark, 0.3);
            margin: 0 -0.5rem 0.5rem;
            padding: 1rem 0.5rem;
            border-bottom: 0;

            &:before {
                content: attr(data-content);
                display: block;
                margin: -1rem 0 1rem;
                font-size: $baseFontSizeReduced;
                font-weight: bold;
                text-transform: uppercase;
                text-align: center;
                border-bottom: solid 1px #AAA;
            }
        }
    }

    &--newsletter {
        display: block;
    }

    .actionShowMore {
        width: 100%;
        text-align: center;
    }
}