/* SITEMAP */

.sitemap {
    &__header {
        font-family: $brandfont;
        font-size: 1.4rem;
        color: $txtColorBase-branded;
        margin-bottom: 1rem;
    }
    
    &__list {
        &--cats,
        &--authors {
            @include foldOutList(6);
            .actionShowMore {
                font-size: $fontSizeReduced;
                margin-top: 0.5rem;
            }
        }

        &--cats {
            li span {
            &:first-letter {
               text-transform: capitalize;
            }
        }
        }

        &--alphabet {
            display: flex;
            margin-bottom: 1rem;

            .alphabet__listitem {
                margin-right: 0.5rem;
                cursor: pointer;
                + .alphabet__listitem {
                    &:before {
                        display: inline-block;
                        content: '-';
                        margin-right: 0.5rem;
                    }
                }
            }
        }

        &--pages {
            display: flex;
            flex-flow: row wrap;
            >li {
                flex: 1;
                width: 100%;
                @media only screen and (min-width: 64px*10) {
                    width: 50%;
                }
                @media only screen and (min-width: 64px*16) {
                    width: 33%;
                }
            }
        }
    }
}