/* BLOCKS NAVIGATION */

.blocks__list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &--summaries,
    &--wishlist {
        display: block;
        .block {
            padding: 1rem 0;
        }
    }

    &--covers {      
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(160px,1fr));
        margin: 0 -0.25rem;

        .block {
            margin: 0.25rem;
            background-color: rgba($bgColor-base, 0.25);

            &.--soon {
                background-color: rgba($bgColor-base, 0.65);
            }
        }

        .block-media {
            &--book {
                &__content {
                    margin: 0 1rem;
                    height: 100%;
                    width: calc(100% - 2rem);

                    .cover__tag {
                        position: absolute;
                        width: 60%;
                        top: 0.2rem;
                        left: 50%;
                        background-color: $bgColor-base-verydark;
                        font-size: 10px;
                        font-weight: bold;
                        color: #FFF;
                        padding: 0.1rem 0.5rem 0.15rem;
                        transform: rotate(-3deg) translateX(-50%);
                        text-align: center;
                    }        

                    .cover__soldout {
                        position: absolute;
                        top: 0.2rem;
                        left: 50%;
                        background-color: yellow;
                        color: #FF0000;
                        font-size: 0.6rem;
                        font-weight: bold;
                        padding: 0.1rem 0.35rem 0.15rem;
                        transform: rotate(-3deg) translateX(-50%);
                    }

                    img {
                        margin: auto;
                        max-height: calc(200px - 1rem);
                    }
                }
            }

            &--product {
                &__content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #FFF;
                    border: solid 1px #CCC;
                    min-height: 9rem;
                    height: calc(100% - 1.25rem + 2px);
                    width: calc(100% - 1rem);
                    max-width: calc(100% - 3rem);
                    margin: auto;
                    padding: 0.5rem;

                    img {
                        margin: auto;
                        border: none;
                        max-height: calc(200px - 1rem);
                    }
                }
            }
        }
    }

    &--newsletter {
        .block {
            margin-bottom: 1rem;
            border: none;
        }
    }

    &--list {
        display: block;
        .block {
            min-height: 0;
            margin-bottom: 0;
            border-bottom: solid 1px #CCC;
            &:last-child {
                border-bottom: 0;
            }
        }
        .block-content--info {
            cursor: pointer;
        }
    }

    &--video {
        margin: 0 -1rem;
        flex-direction: column;
        @media only screen and (min-width: (64px*12)) {
            display: flex;
            flex-flow: row wrap;
        }
    }

    &--recently-viewed {
        //margin-top: 3.5rem;
        //border-top: solid 1px #CCC;
        padding-top: 1rem;

        .blocks__blocks-title {
            margin-bottom: 0.5rem;
            border-bottom: solid 1px #CCC;
        }
    }

    &--products {
        .content {
            background-color: #FFF;
            border: solid 1px #CCC;
            min-height: 10rem;
            height: calc(100% - 1.25rem);
            width: calc(100% - 1rem);
            padding: 0.5rem;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                margin: auto;
                border: none;
            }
        }
    }
}