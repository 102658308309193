/* BLOCK BOOK*/

.block--book {
    min-width: 6rem;
    background-color: #FFF;
    padding: 1rem;

    @at-root .book-wrapper & {
        padding: 1rem 0;
    }
    
    .block-content {
        display: flex;
        flex-flow: column;

        @media only screen and (min-width: (64px * 7)) {
            flex-flow: row wrap;
        }

        &__column {
            flex: 3;
            display: flex;
            flex-direction: column;
            height: 100%;

            &:first-child {
                flex: 1;
                @media only screen and (min-width: (64px * 7)) {
                    max-width: 8rem;
                    width: 100%;
                    margin: 0 auto;
                }
            }

            &:nth-child(2) {
                padding-left: 0;
                @media only screen and (min-width: (64px * 7)) {
                    padding-left: 1rem;
                }
            }
        }

        &--compact {
            justify-content: space-between;
            margin: -0.5rem 0;

            &__image {
                position: relative;
                display: flex; 
                margin-right: 0.5rem;

                .cover__soldout {
                    position: absolute;
                    top: 0.2rem;
                    left: 50%;
                    background-color: yellow;
                    color: #FF0000;
                    font-size: 0.6rem;
                    font-weight: bold;
                    padding: 0.1rem 0.35rem 0.15rem;
                    transform: rotate(-3deg) translateX(-50%);
                }

                .cover__discontinued {
                    position: absolute;
                    width: 110%;
                    top: 0.2rem;
                    left: 50%;
                    background-color: yellow;
                    color: #FF0000;
                    font-size: 0.6rem;
                    font-weight: bold;
                    text-align: center;
                    padding: 0.1rem 0.35rem 0.15rem;
                    transform: rotate(-3deg) translateX(-50%);
                }
            }

            &__title {
                font-size: 1rem;
                font-weight: bold;
                @include color('txtColorContent-branded');
                line-height: 1.1;
                margin-bottom: 0.2rem;
                display: block;
            }

            &__subtitle {
                color: #000;
                font-size: $baseFontSizeReduced;
                font-weight: normal;
                margin: 0.25rem 0;
            }

            .block-content--book__price {
                font-size: 1rem;
                > span {
                    margin-right: 0.5rem;
                    display: flex;
                    align-items: center;
                } 
            } 

            .block-content--info {
                flex: 1;
                display: flex;
                align-items: center;
            }

            .block-content--actions {
                display: flex;
                align-items: center;
            }
        }

        &--notification {
            width: calc(100% - 2rem);
            margin: -2rem 0 2rem;
            padding: 1rem;
            text-align: center;
            background-color: rgba($bgColor-branded-secundary-full, 0.5);
        }

        &--actions {
            justify-content: end;
        }

        &--book {
            &__cover {
                .cover__icon {
                    position: absolute;
                    top: -1rem;
                    right: 1rem;
                    border: solid 1px #CCC;
                    border-radius: 50%;
                    background-color: #FFF;
                    width: 2rem;
                    height: 2rem;
                    background: center center no-repeat;

                    &--wishlist {
                        background: #FFF url('#{$imagesDir}/icons/wishlist-black.svg')  center center no-repeat;
                        background-size: 1.3rem;
                    }
                }

                +.block-content--book__price {
                    margin-top: 0.5rem; 
                    font-size: 1rem;
                    > span {
                        margin-right: 0.25rem;
                    }  
                }
            }

            &__detail {
                
            }
            

            &__title {
                font-size: 1.35rem;
                font-weight: bold;
                line-height: 1.1;
                margin-bottom: 0.2rem;
                margin-top: 0.25rem;
                display: block;
                @include color('txtColorContent-dark');

                h1 {
                    font-size: 1.35rem;
                    font-weight: bold;
                    line-height: 1.1;
                    margin: 0;
                    display: block;
                    @include color('txtColorContent-dark');
                }
            }

            &__subtitle {
                font-size: $baseFontSizeReduced;
                line-height: 1.3;
                margin-bottom: 0.5rem;
                display: block;
            }

            &__author {
                flex: 2;
                font-size: $baseFontSizeReduced;
                line-height: 1.3;
                text-decoration: none;
                @include color('txtColorContent-branded');
                .--connector {
                    margin: 0 0.25rem;
                }
                .--link {
                    text-decoration: underline;
                }
                .--link-all {
                    display: block;
                    color: #000;
                }
            }

            &__info--short {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 1rem;
            }

            &__description--short {
                line-height: $baseLineHeight;
            }

            &__cover-info {
                display: block;
                height: 1.75rem;
                margin-top: 0.25rem;
            }

            &__price {
                position: relative;
                flex: 1;
                display: flex;
                justify-content: flex-end;
                font-size: 1.125rem;
               
                @at-root .blocks__list--covers & {
                    font-size: 1rem;
                    justify-content: space-evenly;
                }
                // &:before {
                //     content: "€";
                //     display: block;
                //     margin-right: 0.5rem;
                // }
                span {
                    display: flex;
                    // span {
                    //     font-size: $baseFontSizeReduced;
                    //     margin-top: 0.5rem;
                    //     @at-root .blocks__list--covers & {
                    //         font-size: 0.6rem;
                    //         margin-top: 0.25rem;
                    //     }
                    // }
                }
            }

            &__preview {
                //position: absolute;
                left: 0;
                .book-tools__tool-link--pdf {
                    width: 1.5rem;
                    height: 1.5rem;
                    margin: 0;
                }

                &-covers {
                    display: flex;
                    margin: 1rem -0.25rem;
                }

                &-front,
                &-back {
                    position: relative;
                    display: flex;
                    border: solid 1px #CCC;
                    width: 3rem;
                    height: 4rem;
                    margin: 0 0.25rem;
                    justify-content: center;
                    
                    .circle {
                        position: absolute;
                        bottom: -0.7rem;
                        right: -0.3rem;
                    }

                    .--soldout {
                        filter: grayscale(0.20);
                        -webkit-filter: grayscale(100%);
                        opacity: 0.75;
                    }
                }

                // &-back {
                //     position: relative;
                //     display: flex;
                //     border: solid 1px #CCC;
                //     width: 3rem;
                //     height: 4rem;
                //     margin: 0 0.25rem;
                //     justify-content: center;
                //     .circle {
                //         position: absolute;
                //         bottom: -0.7rem;
                //         right: -0.3rem;
                //     }
                // }
            }

            &__delivery {
                position: relative;
                font-size: $baseFontSizeReduced;
                margin: -0.25rem 0 0.25rem;
                display: flex;
                justify-content: flex-end;

                .book-delivery--expected {
                    color: $color-dark-sb;
                    font-weight: bold;
                    font-size: 0.8rem;
                }
            }

            &__cart-actions {
                display: flex;
                justify-content: flex-end;
                margin: 0 -0.25rem;
                //border: solid 1px red;

                // &.--sticky {
                //     position: fixed;
                //     background-color: #FFF;
                //     height: 3rem;
                //     z-index: 999;
                // }
            }
        }
    }
}