// MOBILE //

.app-content--mobile {
    .wrapper-header {
        position: relative;
    }

    .wrapper-content,
    .content-wrapper {
        margin-top: 0;
    }
}