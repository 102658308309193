/* SEARCH */

.search {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: $barHeight;
    justify-content: center;
    @include background-color('bgColor-branded-secundary');

    &__input {
        position: relative;
        //z-index: 99;
        
        &:not(.--onpage) {
            //display: none;
            @media only screen and (min-width: (64px*11)) {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            input {
                width: auto;
                min-width: 250px;
                height: $barHeightContent;
                padding: 0.4rem 2rem 0.4rem 0.75rem;
                border: solid 1px;
                border-radius: 5px;
                font-size: $baseFontSizeReduced;
                margin-top: -2px;

                @media only screen and (min-width: (64px*11)) {
                    margin-top: 0;
                }
            }

            .form-group {
                display: flex;
            }

            .search__prefix {
                &-title {
                    border-left: solid 1px #CCC;
                    padding: $barPadding 0.5rem;
                }
                &-holder {
                    .icon--after {
                        height: calc(100% - 0.1rem);
                    }
                }
                &-dropdown {
                    top: 1.2rem;
                }
            }

            .search__buttons {
                display: block;
                width: 1rem;
                height: 1rem;
                position: absolute;
                right: 0.5rem;
                top: 0.35rem;
            }

            .button {
                height: calc(#{$barHeight} - 0.4rem);
                background-color: #CCC;
                border: none;
                border-radius: 0.25rem;
                margin: 0 0 0 0.5rem;
                min-width: 50px;
            }

            .search__button--clear {
                position: absolute;
                right: 1.5rem;
                top: 0;

                @media only screen and (min-width: (64px*14)) {
                    right: 1.25rem;
                }
            }

            .search__button--search {
                width: 1rem;
                height: 1rem;
                display: block;
                text-indent: -999rem;
                background-image: url('../../../images/icons/ic-search-black.svg');
                background-size: 1rem;
                background-position: center center;
                background-repeat: no-repeat;
            }

            .search__select-shop {
                width: fit-content;
                span {
                    color: #000;
                    font-size: $baseFontSizeReduced;
                    font-weight: bold;
                    margin-left: 0.125rem;
                }
            }

            .search__button--select-shop {
                display: block;
                background-image: url('../../../images/icons/ic-search-black.svg');
                background-size: 1rem;
                background-position: left center;
                background-repeat: no-repeat;
                padding-left: 1.25rem;
                padding-bottom: 0.05rem;
            }
        }

        &.--onpage {
            flex-direction: column;

            .form-group {
                margin-bottom: 0.5rem;
            }

            .form-field--text {
                width: calc(100% - 1rem - 2px);
            }

            .search__prefix {
                &-holder {
                    padding: 0.25rem 0.5rem;
                    border: solid 1px #CCC;
                    .icon--after {
                        height: calc(100% - 0.5rem);
                    }
                }
                &-dropdown {
                    top: 1.6rem;
                    width: calc(100%);
                    margin-left: -1px;
                }
            }

            .search__buttons {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                margin-top: 1rem;
            }

            .button {
                margin: 0;
                min-width: 100px;
            }
        }
    }

    &__prefix {
        &-holder {
            position: relative;
            display: flex;
            flex-direction: column;
            font-size: $baseFontSizeReduced;
            cursor: pointer;

            .icon--after {
                position: absolute;
                right: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }
    
            .arrow--down {
                @include arrow(rgba(#000, 0.5), down, 0.2rem);
            }
        }
        &-title {
            width: 200px;
            height: $barHeightContent;
            background-color: #FFF;
            line-height: $barHeightContent;
        }
        &-dropdown {
            position: absolute;
            z-index: 99;
            left: 0;
            display: none;
            background-color: #FFF;
            position: absolute;
            right: 0;
            border: solid 1px #CCC;
            
            &.--active {
                display: block;
            }

            li {
                padding: 0.2rem 0.5rem; 
                &:hover {
                    background-color: $bgColor-branded-secundary;
                }
                
            }
        }
    }

    &-intentions {
        margin: 0 0.2rem 1rem;

        &__header {
            margin-bottom: 0.25rem;
            font-weight: bold;
        }
        &__results {
            cursor: pointer;
        }
        &__list {
            li:last-child {
                .search-item {
                    border-bottom: none;
                }
            }

        }
        &__listitem {
            color: $txtColorBase-link;
        }
    }

    &-select-segment {
        .search-select-segment__list {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            justify-content: center;
            margin: 0.5rem auto;
        }

        .search-select-segment__listitem {
            display: flex;
            flex-direction: column;
            align-items: center;

            label {
                color: rgba($txtColorBase-light, 0.75);
                text-align: center;
            }

            &.--active,
            &:hover {
                font-weight: bold;
                label {
                    color: $color-dark-sb;
                }
            }

            &.--active {
                .search-counter {
                    background-color: rgba($bgColor-branded-secundary-full, 0.75);
                }
            }
        }
    }
}

.search-counter {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 0rem;
    top: 0rem;
    border: solid 1px #CCC;
    border-radius: 0.25rem;
    padding: 0.5rem 0.75rem;
    height: 4rem;

    &__count {
        font-size: 1.25rem;
        line-height: 1;
    }
    &__label {
        font-size: $baseFontSizeReduced;
    }
}

.search-select-segment__list {
    max-width: 40rem;

    .content-segment-clean.search-counter {
        position: relative;
        .search-counter__count {
            
        }
   
    }

    .checkbox + label:before {
        display: none;
    }

    .checkbox + label:after {
        display: none;
    }
}
