// Variables
$checkbox-size: 1rem;
$checkbox-border: #cecece;

.checkbox {
    position: absolute;
    opacity: 0;
    z-index: 999;
    cursor: pointer;
    top: 0;
    left: -0.5rem;
    
    // Text
    & + label {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        left: -1.5rem;
        line-height: $checkbox-size;
        padding-left: 1.5rem;
        color: #666; // Label colour
        text-align: center;
        
        // Checkbox
        &:before {
            position: absolute;
            display: block;
            left: 0;
            top: 0.125rem;
            content: "";
            background: transparent;
            width: $checkbox-size;
            height: $checkbox-size;
            
            box-shadow: inset 0 0 0 1px $checkbox-border;
            border-radius: 4px;
            transition: 200ms ease-in-out all;
        }

        &:after {
            @at-root .search-select-segment__listitem.--included & {
                position: absolute;
                //display: block;
                display: none;
                top: -0.55rem;
                left: -0.3rem;
                content: "";
                width: 2rem;
                height: 2rem;
                background-image: url('../../images/icons/ic-check-black.svg');
                background-size: 1.25rem;
                background-repeat: no-repeat;
                background-position: center center;
                
                transition: 200ms ease-in-out all;
                opacity: 0.25;
            }
        }

        &:after {
            @at-root .search-select-segment__listitem.--excluded & {
                // position: absolute;
                // display: block;
                // top: -0.62rem;
                // left: -0.75rem;
                // content: "";
                // width: 2.5rem;
                // height: 2.5rem;
                // //background-image: url('../../images/icons/ic-clear-black.svg');
                // background-size: 1.25rem;
                // background-repeat: no-repeat;
                // background-position: center center;
                
                // transition: 200ms ease-in-out all;
            }
        }
    }
    
    // Hover
    & + label:hover{
        color: #333;
    }
    
    // Focus
    & + label:focus{
        outline: none;
    }
    
    // Checked
    &:checked {
        & + label {
            &:after {
                position: absolute;
                display: block;
                top: -0.55rem;
                left: -0.3rem;
                content: "";
                width: 2rem;
                height: 2rem;
                background-image: url('../../images/icons/ic-check-green.svg');
                background-size: 1.25rem;
                background-repeat: no-repeat;
                background-position: center center;
                
                transition: 200ms ease-in-out all;
            }
        }
    }
    
    // Disabled
    &:disabled{
        & + label{
            &:before{
                background: #f2f2f2; // Disabled colour
                box-shadow: none;
            }
            &:after{
                border-color: transparent;
            }
        }
    }
    
    // Disabled Checked
    &:checked:disabled{
        & + label{
            &:before{
                background: #f2f2f2;
                box-shadow: none;
            }
            &:after{
                border-color: #cecece;
            }
        }
    }
}