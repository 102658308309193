/* GRADIENTS */

$gradients: () !default;
$gradients: map-merge((
    textemph: linear-gradient(0deg, rgba(0, 0, 0, 100) -50%, rgba(0, 0, 0, 0) 60%, transparent),
    chill: linear-gradient(135deg, #09e485 0%,#0291e3 100%),
    warm: linear-gradient(135deg, #F2CD48 0%,#FD803F 100%),
    hot: linear-gradient(135deg, #F9445A 0%,#FD803F 100%),
    cold: linear-gradient(135deg, #8019C6 0%,#2F93CC 100%)
), $gradients);

// Gradient backgrounds
@mixin bg-gradient($parent, $gradient) {
    #{$parent} {
      background: $gradient;
    }
}

// Gradient Classes 
@each $gradient, $value in $gradients {
    @include bg-gradient(".bg-gradient-#{$gradient}", $value);
}