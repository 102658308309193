/* SEGMENT CONTENT */

.segment {
    &__header {
        margin: 1.5rem 0;
        font-family: $brandfont;
        font-size: 1.35rem;
        font-weight: bold;
        line-height: 1.1;
        color: $txtColorBase-branded;
    }

    &__content {
        &.book__description,
        &.book__reviews,
        &.book__specs {
            padding: 0 1rem;
        }

        &--form {
            margin: auto 1rem;
        }

        &.book__description {
            max-width: 25rem;
            margin: auto;

            img {
                //SbC was auto; 125px
                //width: 100%;
                max-width: 400px;

                &.--auto {
                    width: auto;
                }

                &.--cover {
                    height: 150px;
                    max-width: fit-content;
                }
            }
        }
    }
}

.content-segment {
    &--clean {
        + .content-segment {
            margin-top: 2rem;
        }
    }

    &--feedback {
        margin-bottom: 1rem;
        text-align: center;
    }

    &__header {
        font-family: $brandfont;
        @include color('txtColorContent-branded');
    }

    h1 {
        font-size: 1.35rem;
        font-weight: bold;
        line-height: 1.35;
        margin: 1.5rem 0 0;
    }

    h2 {
        margin: 0 0 1rem;
        font-size: 1.1rem;
    }

    h3 {
        margin-top: 0;
        font-size: 1.1rem;
    }

    h4 {
        margin-bottom: 0;
        + p {
            margin-top: 0;
        }
    }

    &__content {
        p {
            margin: 0 0 1rem;
            &.p--emphasis {
                background-color: rgba(#CCC, 0.5);
                margin: 0 -1rem 1rem;
                padding: 1rem 1.2rem 1.2rem;
        
                & > strong {
                    display: block;
                }
            }
        }

        &--intro {
            p {
                margin: 0;
                + p {
                    margin-top: 1rem;
                }
            }
        }

        + .content-segment__content {
            margin-top: 1rem;
        }
    }

    &__list {
        font-size: $fontSizeReduced;

        &--textlines {
            @include foldOutList(10);
            li {
                min-width: 100%;
            }
            .actionShowMore {
                font-size: $fontSizeReduced;
                margin-top: 0.5rem;
                cursor: pointer;
            }
        }
    }

    &__listitem {
        display: flex;
        &-wrapper {
            display: flex;
        }
        &-count {
            display: inline-block;
            width: 1.5rem;
            margin-right: 0.5rem;
            text-align: right;

            + span {
                width: 85%;
                display: inline-block;
                &.--selected {
                    color: $txtColorBase-branded;
                    font-weight: bold;
                }

                span {
                    display: inline;
                }
            }
        }
    }
}