/* ANKEILERS */

.ankeiler {
    padding: 1rem;
    background-color: $bgColor-base-light;
    
    &--newest-books {
        min-height: 300px;
    }

    &--shipping {
        min-height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 1.5rem;

        ul {
            list-style-type: disc;
            font-size: $fontSizeReduced;
        }
    }

    &--newsletter {
        min-height: 100px;

        .newsletter__subtitle {
            font-size: $fontSizeReduced;
        }

        .newsletter__actions {
            flex-direction: row;
            justify-content: center;

            span {
                margin: 0 0.5rem;
            }
        }

        .link--subscribe-subtitle {
            margin-top: 1rem;
        }
    }

    &__block-title {
        color: $txtColorBase-branded;
        font-weight: bold;

        &--top20 {
            font-weight: bold;
            font-size: 1.5rem;
        }
    }
}