/* CAROUSEL */

.carousel {
    &--newest-books {
        width: 100%;
    }

    &--books,
    &--banner {
        &__slide {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__slide-content {
            border: solid 1px #CCC;
            width: 7rem;
            img {
                width: 100%;
                float: left;
                margin: 0;
            }
        }

        &__btn--add {
            padding: 0.25rem;
            background: url('../../images/icons/cart-inverted.svg') no-repeat;
            background-color: $bgColor-btn-primary;
            background-size: 80%;
            background-position: center center;
            text-indent: -999rem;
            margin-top: -1rem;
            margin-right: -3.5rem;
            cursor: pointer;
            &:hover {
                background-color: #000;
            }
        }

        .swiper-button-prev {
            left: -0.5rem;
        }

        .swiper-button-next {
            right: -0.5rem;
        }
    }

    &--banner {
        .swiper-container {
            border: solid 1px #CCC;
            cursor: pointer;
            width: 13rem;
            z-index: 0;
        }

        .swiper-wrapper {
            min-height: 100px;
            align-items: center;
        }

        &__slide {
            min-height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__slide-content {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 1rem 0;
            margin-top: -0.35rem;
            width: 100%;
            flex: 1;
            text-align: center;
            border: none;
        }
            
        &__slide-text {
            padding: 0 0.5rem;
            width: calc(100% - 1rem);
            font-size: $fontSizeReduced;
        }

        &__btn--add {
            position: relative;
            display: flex;
            margin: -0.5rem auto auto;
            z-index: 10;
        }

        .swiper-button-prev,
        .swiper-button-next {
            display: none;
        }
    }

    &--promobooks {
        .block-content--book__cover-info {
            display: none;
        }
        .carousel--banner__slide {
            width: 80%;
            margin: auto;
        }
    }

    &--home {
        position: relative;
        width: 85%;
        margin: 0 auto 1rem;
        min-height: 14rem;
        @media only screen and (min-width: 540px) {
            width: 80%;
        }

        .swiper-container {
            .swiper-button-next,
            .swiper-button-prev {
                display: none;
            }
        }

        .swiper-button-next-out, 
        .swiper-button-prev-out {
            border: solid 2px $bgColor-btn-primary;
            border-radius: 50%;
            background-color: $bgColor-btn-primary;
            width: 1.5rem;
            height: 1.5rem;
            color: #FFF;
            top: 45%;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            
            &:after {
                font-family: swiper-icons;
                font-size: 1rem;
            }
            &.swiper-button-disabled {
                opacity: 0.35;
                cursor: auto;
                pointer-events: none;
            }
            &:focus {
                outline: none;
            }
        }

        .swiper-button-prev-out {
            left: -2.5rem;
            right: auto;
            
            &:after {
                content: 'prev';
                margin-right: 0.15rem;
            }
        }

        .swiper-button-next-out {
            right: -2.5rem;
            left: auto;
            
            &:after {
                content: 'next';
                margin-left: 0.15rem;
            }
        }

        .swiper-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: 1rem;
        }
    }

    &--books {
        position: relative;
        width: 65%;
        margin: auto;

        .swiper-container {
            .swiper-button-next,
            .swiper-button-prev {
                display: none;
            }
        }

        .swiper-button-next-out, 
        .swiper-button-prev-out {
            border: solid 2px $borderColorBase;
            border-radius: 50%;
            width: 1.5rem;
            height: 1.5rem;
            color: $borderColorBase;
            top: 40%;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            
            &:after {
                font-family: swiper-icons;
                font-size: 1rem;
            }
            &.swiper-button-disabled {
                opacity: 0.35;
                cursor: auto;
                pointer-events: none;
            }
            &:focus {
                outline: none;
            }
        }

        .swiper-button-prev-out {
            left: -2rem;
            right: auto;
            
            &:after {
                content: 'prev';
                margin-right: 0.15rem;
            }
        }

        .swiper-button-next-out {
            right: -2rem;
            left: auto;
            
            &:after {
                content: 'next';
                margin-left: 0.15rem;
            }
        }
    }

    &--newsletter {
        .swiper-container {

        }

        .swiper-slide {
            margin-bottom: 2rem;
        }

        &__slide {
            &.block--book {
                padding: 0 2rem;
                margin: 0.75rem 0 2rem;
            }
        }

        .swiper-pagination {
            display: none;
        }

        .swiper-pagination--covers {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-around;
            padding: 0 1.55rem;

            &__cover {
                position: relative;
                flex: 1;
                padding: 0.25rem;
                margin: 0.25rem;
                min-width: 8rem;
                max-width: 8rem;
                max-height: 12rem;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                cursor: pointer;
                @media only screen and (min-width: (64px*12)) {
                    width: 125px;
                    max-height: auto;
                }

                img {
                    height: 100%;
                    width: auto;
                    border: solid 1px #CCC;
                }

                .cover__icon {
                    position: absolute;
                    top: -1rem;
                    right: 1rem;
                    border: solid 1px #CCC;
                    border-radius: 50%;
                    background-color: #FFF;
                    width: 2rem;
                    height: 2rem;
                    background: center center no-repeat;

                    &--wishlist {
                        background: #FFF url('../../images/icons/wishlist-black.svg')  center center no-repeat;
                        background-size: 1.3rem;
                    }
                }
            }
        }
    }

    &-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        //margin-top: -2rem;

        .swiper-button-pause,
        .swiper-button-play {
            border: solid 2px rgb(80, 60, 60);
            border-radius: 50%;
            width: 1.5rem;
            height: 1.5rem;
            border: solid 1px #CCC;
            margin: 0 0.25rem;
            text-indent: -999rem;
            background-size: 60%;
            background-position: center center;
            background-repeat: no-repeat;
        }
    
        .swiper-button-play {
            right: 0;
            left: auto;
            background-image: url('../../images/icons/ic-play.png');
            
            &.--pause {
            }

            &.--play {
                opacity: 0.3;
            }
        }
    
        .swiper-button-pause {
            right: 0;
            left: auto;
            background-image: url('../../images/icons/ic-pause.png');
            background-size: 60%;
            background-position: 45% center;

            &.--pause {
                opacity: 0.3;
            }

            &.--play {

            }
        }
    }
}
