/* SOCIAL LINKS */

.social-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0.25rem;
    height: 2rem;

    .social-link {
        background-size: 100% 100%;
        background: left center no-repeat;
        border: solid 1px #AAA;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        margin: 0 0.25rem;

        &--email {
            background-image: url('../../images/icons/email.svg');
            width: 1.65rem;
            height: 1.65rem;
        }

        &--facebook {
            background-image: url('../../images/icons/facebook-white.svg');
        }

        &--twitter {
            background-image: url('../../images/icons/twitter-white.svg');
        }

        // &--instagram {
        //     background-image: url('../../images/icons/instagram-white.svg');
        // }

        &--youtube {
            background-image: url('../../images/icons/youtube-white.svg');
        }

        @at-root .footer__social & {
            &--facebook {
                background-image: url('../../images/icons/facebook-black.svg');
            }

            &--twitter {
                background-image: url('../../images/icons/x-black-trans2.png');
                background-position: center;
                background-size: 60%;
            }

            &--instagram {
                background-image: url('../../images/icons/instagram-black.png');
                background-size: 1.3rem;
                background-position: center;
            }

            &--youtube {
                background-image: url('../../images/icons/youtube-black.svg');
            }
      
            &--telegram {
                background-image: url('../../images/icons/telegram-black.png');
                background-size: 1.3rem;
                background-position: center;
            }
        }

        &--mastodon {
            width: calc(2rem - 2px);
            height: calc(2rem - 2px);
            display: inline-block;
            border: solid 1px #CCC;
            border-radius: 50%;
            margin: 0 0.125rem;
            background-image: url('../../images/icons/ic-mastodon-black.png');
            background-size: 100%;
        }
    }
    @at-root .footer__social & {
        padding: 2rem 0 1rem;;
    }
}