/* CART STEPS */

.cart-steps {
    display: flex;
    align-items: flex-start;

    .cart-step {
        @include fullflex();
        flex: 1;
        flex-direction: column;

        &__nr {
            @include fullflex();
            border: solid 1px #CCC;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            justify-content: center;
        }

        &__title {
            font-size: $fontSizeReduced;
            text-align: center;
            margin-top: 0.5rem;
        }

        &.--active {
            .cart-step__nr {
                @include background-color('bgColor-branded-dark');
                color: #FFF; 
            }
            .cart-step__title {
                font-weight: bold;
            }
        }
    }

    +.cart-data {
        margin-top: 1rem;
    }
}

