@mixin foldOutListSummaries($initialLines) {
    //width: 100%;
    margin: 0;
    padding: 0;

    $nr1: 1;
    $nr2: 2;
    $nr3: 3;
    $nr4: 4;

    $break1: 350px;
    $break2: 512px;
    $break3: 705px;
    $break4: 898px;
    $break5: 1014px;
    $break6: 1218px;

    li {
        display: inline-block;
        width: 100%;
        vertical-align: top;

        // @media only screen and (min-width: (64px*6)) {
        //     width: 50%;
        // }

        // @media only screen and (min-width: (64px*16)) {
        //     width: (100% / $nr4);
        // }

        &.actionShowMore {
            width: 100%;
            text-align: center;
        }
    }

    &.--collapsed {
        li {
            display: none;

            &:nth-child(n+1):nth-child(-n+#{$initialLines}):not(.actionShowMore) {
                display: inline-block;
            }

            &:nth-last-child(n + #{$initialLines + $nr1 + 1}):first-child ~ li.actionShowMore {
                display: block;
            }

            //2
            @media only screen and (min-width: ($break1)),
            only screen and (min-width: ($break4)) and (max-width: ($break5)) {
                &:nth-child(n+1):nth-child(-n + #{$initialLines * $nr2}):not(.actionShowMore) {
                    display: inline-block;
                }

                &:nth-last-child(n + #{$initialLines + $nr2 + 1}):first-child ~ li.actionShowMore {
                    display: block;
                }

                &:nth-child(n+1):nth-child(-n + #{$initialLines * $nr2}) + li.actionShowMore {
                    display: none;
                }
            }

            //3
            @media only screen and (min-width: ($break2)) and (max-width: ($break3)),
            only screen and (min-width: ($break5)) and (max-width: ($break6)) {
                &:nth-child(n+1):nth-child(-n + #{$initialLines * $nr3}):not(.actionShowMore) {
                    display: inline-block;
                }

                &:nth-last-child(n + #{$initialLines + $nr3 + 1}):first-child ~ li.actionShowMore {
                    display: block;
                }

                &:nth-child(n+1):nth-child(-n + #{$initialLines * $nr3}) + li.actionShowMore {
                    display: none;
                }
            }

            //4
            @media only screen and (min-width: ($break3)) and (max-width: ($break4)),
            only screen and (min-width: ($break6)) {
                &:nth-child(n+1):nth-child(-n + #{$initialLines * $nr4}):not(.actionShowMore) {
                    display: inline-block;
                }

                &:nth-last-child(n + #{$initialLines + $nr4 + 1}):first-child ~ li.actionShowMore {
                    display: block;
                }

                &:nth-child(n+1):nth-child(-n + #{$initialLines * $nr4}) + li.actionShowMore {
                    display: none;
                }
            }
        }
    }
}
