.content-section--login-option {
    
    text-align: center;

    .form-wrapper {
        max-width: 500px;
        margin: auto;
    }

    + .content-section--login-option {
        border-top: solid 1px #CCC;
        padding-top: 2rem;
    }
}

.cart-data {
    div[class*='content-section'] {
        margin-top: 1rem;
    }

    div[class*='content-section--link-login'] {
        border-top: solid 1px #CCC;
        margin-top: 3rem;
        padding-top: 2rem;
        text-align: center; 
        
    }
}