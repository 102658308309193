/* BLOCK BOOK TOOLS */

.block--book-tools {
    margin-bottom: 1rem;
    border: none;
    display: flex;
    flex-direction: column;
    min-height: 0;

    @media only screen and (min-width: (64px*8)) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.book-tools {  
    &__group {
        display: block;
        margin-bottom: 0.4rem;

        @media only screen and (min-width: (64px*8)) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 0;

            &--social {
                justify-content: flex-end;
            }
        }
    }
    &__tool {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0.25rem;
        flex: 1;

        &--email {
            max-width: 9.5rem;
            margin: auto;
        }

        &--pdf {
            @at-root .blocks__list--summaries & {
                @media only screen and (max-width: (64px*7)) {
                    position: absolute;
                    bottom: 1rem;
                    right: -1rem;
                    transform: translateX(100%);
                }
            }
        }
    }
    
    &__tool-title {
        font-size: $fontSizeReduced;
        text-align: center;
        line-height: 1.35;
    }

    &__tool-link {
        background-size: 100% 100%;
        background: center center no-repeat;
        border: solid 1px #AAA;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        margin: 0.25rem;
        a {
            position: relative;
            display: block;
            text-indent: -999rem;
            height: 100%;
        }

        &--cover {
            display: block;
            position: absolute;
            bottom: 0.5rem;
            right: -1rem;
            margin: 0;
            background-color: #FFF;
            background-image: url('../../../images/icons/ic-enlarge-white.png');
            background-size: 70%;
            @media only screen and (min-width: (64px*7)) {
                bottom: -0.25rem;
                right: -0.5rem;
            }
        }

        &--pdf {
            background-image: url('../../../images/icons/ic-book-black.jpg');
            background-size: 110%;
            border: none;
            border-radius: 0;
            .link-pdf {
                height: 2rem;
            }
        }

        &--video {
            background-image: url('../../../images/icons/ic-monitor-black.png');
            background-size: 100%;
            border: none;
            border-radius: 0;
        }

        &--audio {
            background-image: url('../../../images/icons/ic-headphones-black.png');
            background-size: 100%;
            border: none;
            border-radius: 0;
        }

        &--downloads {
            background-image: url('../../../images/icons/ic-download.png');
            background-size: 85%;
            background-position: center;
            border: none;
            border-radius: 0;
        }
    }
}