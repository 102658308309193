/* VARIABLES */

// directory settings
$fontDir: "../../fonts";
$imagesDir: "../../../images";

// grid settings
$contentWidth: 40rem;
$triggerGridChangeOverlay: calc(8*64px - 1px);
$triggerGridChange: calc(14*64px - 1px);
$triggerMobileNav: calc(16*64px - 1px);

$widthContentSearchOverlay: 35rem;

// z-indices;
$zHeader: 100;
$zContent: 90;
$zFooter: 80;

// nav bar en search bar
$barHeight: 1.7rem;
$barHeightContent: calc(#{$barHeight} - 0.7rem);
$barPadding: 0.1rem;

// blocks settings
$initialLines: 2;

// colors
$color: #000;
$color-inverted: #FFF;
$color-dark-sb: #9e1e2b;
$color-darker-sb: #96281B;
$color-light-sb: #E2B873;

$color-alert: red;
$color-check: green;

// text colors
$txtColorBase: #000;
$txtColorBase-inverted: #FFF;
$txtColorBase-branded: #9e1e2b;
$txtColorBase-light: #666;
$txtColorBase-positive: green;
$txtColorBase-link: #9e1e2b;

// background colors
$bgColor-base: #E3E0DB;
$bgColor-base-light: #FFF;
$bgColor-base-lightdark: #F5F4F2;
$bgColor-base-middle: #EDEBE8;
$bgColor-base-dark: #d7d2cb;
$bgColor-base-verydark: #888;

$bgColor-branded-dark: #AE2417;
$bgColor-branded-secundary: #E2B873;
$bgColor-branded-secundary-full: #E2CCA7;

$bgColor-btn-primary: green;

// border colors
$borderColorBase: #CCC;
$borderColor-branded: #96281B;