/* BLOCK EBOOK*/

.blocks__list--ebooks {
    display: flex;
    flex-direction: column;
    margin: 0 -0.25rem;

    .block {
        padding: 1rem 0.25rem;
        @at-root .--list & {
            min-height: 4rem;
            align-items: center;
        }
    }

    &.--summaries {
        .block-content__column {
            &:first-child {
                align-items: center;
                @media only screen and (min-width: (64px * 7)) {
                    align-items: inherit;
                }
            }
        }

        .link--read-more {
            display: inline;
            text-decoration: underline;
            margin: 0 0.25rem;
            &:after {
                display: none;
            }
        }
    }

    .block--specs-summary__list {
        margin-bottom: 0.5rem;
    }

    &.--list {
        .block {
            min-height: 0;
            margin-bottom: 0.25rem;
            padding: 0 0 0.5rem;
            border-bottom: solid 1px #CCC;
        }

        .block-content {
            min-height: 4rem;
            align-items: center;
        }

        .block-content__column {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &:first-child {
                max-width: 4rem;
                align-items: center;
            }

            &:nth-child(2) {
                padding-left: 0.25rem;
            }
        }

        .block-content--book__titles {
            margin: 0.5rem 0;
        }

        .block-content--book__title {
            font-size: 1rem;
        }

        .block--ebook__author {
            margin-bottom: 0;
        }
    }

    &.--covers {
        flex-direction: row;
        margin: 0 -0.25rem;
        .block {
            max-width: 8rem;
        }
    }
}

.block--ebook {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: rgba($bgColor-base, 0.25); 

    &__title {
        margin-bottom: 0.5rem;
        @media only screen and (min-width: (64px * 16)) {
            min-height: 3rem;
        }

        @at-root .--list & {
            font-size: $fontSizeReduced;
        }
    }

    &__subtitle {
        display: block;
        margin-bottom: 0.5rem;
        line-height: 1.25rem;
    }

    &__author {
        display: block;
        margin-bottom: 1rem;
        font-size: $fontSizeReduced;
        color: $txtColorBase-branded;
    }

    &__options {
        font-size: $fontSizeReduced;
    }

    &__remark {
        font-size: $fontSizeReduced;
        margin: 0 0 1rem 0;
        font-style: italic;
    }

    &__txtsupplement {
        font-size: $fontSizeReduced;
        margin: 1rem 0 0.5rem 0;
    }

    &__image {
        border: solid 1px #CCC;
        min-width: 6rem;
        max-width: 10rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.25rem;

        @at-root .--covers & {
            margin: 0;
            height: 10rem;
        }

        @at-root .--list & {
            max-width: 4rem;
            min-width: 4rem;
            border: none;
            margin: 0 auto 0;
        }

        img {
            max-width: calc(100% - 1rem);
        }
    }

    &__descr {
        display: block;
        margin-bottom: 1rem;
    }

    &__actions {
        position: relative;
        bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
        margin-top: 0.5rem;
        @at-root .--list & {
            margin: 0 0.25rem;
        }

        @at-root .--covers & {
            margin-top: 0.35rem;
        }

        span {
            display: block;
            font-size: $fontSizeReduced;
            line-height: 1.15rem;
            text-align: right;
            margin-right: 0.25rem;
        }

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
        }

        .link--read-more {
            margin-right: 1rem;
        }
    }

    &__btn {
        + .block--ebook__btn {
            margin-left: 2rem;

            @at-root .--covers & {
                margin-left: 0;
            }

            @at-root .--list & {
                margin-left: 0;
            }
        }
    }

    &__btn--add {
        width: 1.75rem;
        height: 1.75rem;
        padding: 0.25rem;
        border-radius: 0.5rem;
        background: url('../../../images/icons/cart-inverted.svg') no-repeat;
        background-color: $bgColor-btn-primary;
        background-size: 80%;
        background-position: center center;
        text-indent: -999rem;
        margin: 0 0 0 0.25rem;
        &:hover {
            background-color: #000;
        }

        &.--disabled {
            background-color: #CCC;
            border: solid 1px #CCC;
            cursor: default;
        }

        @at-root .--list & {
            width: 1rem;
            height: 1rem;
        }

        @at-root .--covers & {
            width: 1rem;
            height: 1rem;
        }
    }

    &__btn--download {
        position: relative;
        align-self: flex-end;
        width: 1.75rem;
        height: 1.75rem;
        padding: 0.25rem;
        border-radius: 50%;
        background-color: $bgColor-btn-primary;
        margin: 0 0 0 0.25rem;
        display: block;
        cursor: pointer;

        &:after {
            content: "";
            position: absolute;
            width: 1.75rem;
            height: 1.75rem;
            background: url('../../../images/icons/ic-download.png') no-repeat;
            background-size: 80%;
            background-position: center center;
            filter: invert(1);
        }

        &:hover {
            background-color: #000;
        }

        &.--disabled {
            background-color: #CCC;
            border: solid 1px #CCC;
            cursor: default;
        }

        @at-root .--list & {
            width: 1rem;
            height: 1rem;

            &:after {
                width: 1rem;
                height: 1rem;
            }

        }

        @at-root .--covers & {
            width: 1rem;
            height: 1rem;

            &:after {
                width: 1rem;
                height: 1rem;
            }
        }
    }

   
}