/* BUTTONS */

button {
    &:focus,
    &:active {
        outline: none;
    }
}

.button,
.btn {
    height: 2rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 0.75rem;
    margin: 0 0.25rem 0.25rem;
    padding: 0 0.5rem;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    line-height: 1.1;

    &--primary {
        background-color: green;
        border: solid 1px green;
        @include color('btnColor-primary');
        &:hover {
            background-color: #000;
        }

        &.--active {
            background-color: green;
            border: solid 1px green;
        }

        &.--disabled {
            background-color: #CCC;
            border: solid 1px #CCC;
            cursor: default;
        }

    }

    &--secundary {
        border: solid 1px #CCC;
        @include color('btnColor-secundary');
        &:hover {
            background-color: #000;
            color: #FFF;
        }

        &.--disabled {
            background-color: none;
            border: solid 1px #CCC;
            color: rgba(#000, 0.7);
            cursor: default;
            &:hover {
                background-color: transparent;
            }
        }

        &.--inline {
            display: inline;
            padding: 0.5rem 1rem;
        }
    }


    &--tertiary {
        border: none;
        text-decoration: underline;
        margin: 0 0.25rem;
        padding: 0;
        background-color: transparent;
    }

    &--text {
        margin-top: -1rem;
        margin-right: -0.75rem;
    }

    &--submit {
        border-radius: 0;
        height: 2rem;
    }

    &--wishlist {
        background: left 0.25rem center no-repeat;
        padding-left: 2.5rem;
        background-color: #FFF;
        background-image: url('../../images/icons/wishlist-black.svg');
        background-size: 1.5rem;  
        &:hover {
            background-image: url('../../images/icons/wishlist-white.svg');
        }
        &.--disabled {
            opacity: 0.3;
            background-image: url('../../images/icons/wishlist-black.svg');
            &:hover {
                background-image: url('../../images/icons/wishlist-black.svg');
            }
        }

        span {
            position: absolute;
            left: 0;
            display: inline-block;
            width: 2.1rem;
            height: 2.1rem;
        }

        &.--active {
            span {
                background-image: url('../../images/icons/ic-wishlist.svg');
                @include background-color('bgColor-branded-dark');
                background-size: 70%;
                background-repeat: no-repeat;
                background-position: center center;
                border-radius: 0.5rem;
            }

            &.btn--icon {
                span {
                    width:1.725rem;
                    height: 1.725rem;
                    top: -0.1rem;
                    left: -0.1rem;
                }
            }
        }
    }

    &--cart {
        padding-left: 2.5rem;
        background-repeat: no-repeat;
        background-color: $bgColor-btn-primary;
        background-image: url('../../images/icons/cart-inverted.svg');
        background-size: 1.5rem;  
        background-position: left 0.5rem center;
    }

    &--close {
        position: sticky;
        right: 0.5rem;
        top: 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        float: right;
        span {
            margin: 0;
            line-height: 0.8;
        }
    }

    &--icon {
        text-indent: -999rem;
        padding: 0.25rem;
        width: 1.25rem;
        height: 1.25rem;
        background-size: 80%;
        background-position: center center;
        background-repeat: no-repeat;
        overflow: hidden;

        &.icon--close {
            background-image: url('../../images/icons/ic-close-black.svg');
        }

        &.icon--clear {
            background-image: url('../../images/icons/ic-clear-black.svg');
            background-size: 1.1rem;
        }
    }
}

.dialog-footer__button {
    height: 2rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 0.75rem;
    margin: 0 0.25rem 0.25rem;
    padding: 0 0.5rem;
    min-width: 5rem;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    
    &--primary {
        background-color: green;
        border: solid 1px green;
        color: #FFF;
    }

    &--secundary {
        border: solid 1px #CCC;
        color: #666;
    }

    &:hover {
        background-color: #000;
    }
}