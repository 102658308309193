/* BLOCK  */

.block {
    flex: 1;
    min-width: 10rem;
    min-height: 10rem;

    .content-wrapper__sidebar & {
        border: none;
    }

    &--latest-update {
        position: relative;
        text-align: right;
        min-height: 0;
        font-size: $fontSizeReduced;
        color: #AAA;
        margin-bottom: 1rem;
        margin-top: -0.75rem;
        @media only screen and (min-width: (64px*12)) {
            display: block;
            position: absolute;
            right: 0;
            top: 0.25rem;;
            margin-bottom: 0;
            margin-top: 0;
        }

        + .content-wrapper__segment {
            margin-top: 2rem;
        }
    }

    &--notification {
        border: solid 1px $bgColor-branded-dark;
        background-color: rgba($bgColor-branded-secundary, 0.35);
        text-align: center;
        min-height: auto;
        min-width: auto;
        padding: 0.75rem 1rem 1rem;
        line-height: 1.4rem;
        margin-bottom: 2rem;
    }

    &--quote {
        flex: 0;
        border: none;
        //margin: 2rem 0 1.5rem;
        padding: 1rem 2rem;
        margin-bottom: 1.5rem;
        min-height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.--light {
            background-color: #FFF;
        }
    }

    &--action {
        border: none;
        min-height: 0;
        cursor: pointer;
        flex: none;
        display: block;
    }

    &--author {
        position: relative;
        margin: 0.25rem;
        cursor: pointer;
        &__title {
            position: absolute;
            bottom: 0;
            font-family: $brandfont;
            font-weight: bold;
            margin: 1rem;
        }
    }

    &--categories,
    &--tags {
        min-height: 0;
        //display: flex;
        flex-direction: row;
        &__title {
            font-size: $fontSizeReduced;
            font-weight: bold;
            color: $txtColorBase-branded;
            margin-right: 1rem;
        }
        &__list {
            display: flex;
            flex-flow: row wrap;
            margin-top: -0.25rem;
        }
        &__listitem {
            font-size: $fontSizeReduced;
            margin: 0 0.35rem 0 0;
            //text-decoration: underline;

            + li {
                &:before {
                    content: "-";
                    margin-right: 0.35rem;
                }
            }
        }

        + .block--tags {
            margin-top: 0.5rem;
        }
    }

    &--category {
        position: relative;
        margin: 0.25rem 0 0 2rem;
        padding: 0 0.25rem 0.05rem 0;
        min-height: 2rem;
        border: none;
        list-style-type: circle;
        cursor: pointer;
        &__title {
            //position: absolute;
            //margin: 1rem;
            display: flex;
            //justify-content: center;
            bottom: 0;
            font-family: $brandfont;
            //font-weight: bold;
            
        }
    }

    &--ebook,
    &--audiobook {
        position: relative;
        margin: 0.25rem;
        padding: 1rem;
        &__title {
            font-family: $brandfont;
            font-weight: bold;
            margin: 0 0 1rem;
        }
    }

    &--genre {
        overflow: hidden;
        position: relative;
        background-size: cover;
        margin: 0.25rem;
        cursor: pointer;
        &__bg {
            position: absolute;
            width: 100%;
        }
        &__title {
            position: absolute;
            bottom: 0;
            font-family: $brandfont;
            font-weight: bold;
            margin: 1rem;
        }
    }

    &--image-upload {
        min-height: 0;

        &__container {
            margin-top: 1rem;
        }
        &__preview {
            @at-root .book-wrapper & {
                width: 5rem;
            }
        }
    }

    &--loggedin {
        border: none;
        text-align: center;
    }

    &--newsletter {
        text-align: center;
        
        .block__block-subtitle + .block__block-content {
            border: solid 1px transparent;
            margin-top: 2rem;

            .link--read-more {
                font-weight: bold;
            }
        }

        .newsletter__actions {
            justify-content: center;
            span:last-child {
                margin-right: 0;
            }
        }
    }

    &--review {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: solid 1px #CCC;
    }

    &--select-segment {
        background-color: $bgColor-base-middle;
        min-height: 10rem;
        margin: 0 auto;
        max-width: 35rem;

        .block__block-readmore {
            margin-top: 1rem;
            padding: 0.5rem 0.75rem 0.5rem;
            text-align: right;        
        }
    }

    &--shipping {
        ul {
            list-style-type: disc;
            font-size: $fontSizeReduced;
            margin-bottom: 0.5rem;
            li {
                display: inline-block; 
            }
        }
        span {
            font-size: $fontSizeReduced;
        }
    }

    &--sidebar {
        min-height: 0;
    }

    &--specs-summary {
        min-height: 0;
        &__list {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin: 0 -0.35rem 2rem;
            font-size: $fontSizeReduced;

            + .block--specs-summary__list {
                margin-top: -1.9rem;
                &--actions {
                    justify-content: space-between;
                    margin: -1rem 0 2rem 0;
                    li {
                        padding: 0;
                        margin-left: 2rem;
                        border-left: 0;

                        &:first-child {
                            margin-left: 0;
                            .blocks__list--action-icons {
                                margin-left: 0;
                            }
                        }

                        &:nth-child(2) {
                            display: none;
                            @media only screen and (min-width: (64px*10)) {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
        &__listitem {
            padding: 0 0.35rem;
            border-left: solid 1px #AAA;
            line-height: 1rem;
            &:first-child {
                border: none;
            }
        }
    }

    &--downloads {
        li {
            margin-bottom: 0.5rem;
        }
        .link--pdf {
            display: flex;
            align-items: center;
            &:before {
                content: "";
                width: 1.75rem;
                height: 1.75rem;
                padding: 0.25rem;
                border-radius: 50%;
                background: url('../../../images/icons/ic-download.png') no-repeat;
                background-color: #CCC;
                background-size: 60%;
                background-position: center center;
                text-indent: -999rem;
                margin: 0 1rem 0 0;
                display: block;
                cursor: pointer;
            }
        }
    }

    &--video {
        width: calc(100% - 2rem);
        padding: 1rem;
        @media only screen and (min-width: (64px*12)) {
            width: calc(50% - 2rem);
        }

        @at-root .block--focus-video & {
            width: 100%;
            margin: -1rem;
        }

        &-filter {
            .block--categories + .block--tags {
                margin-top: 0;
            }

            .block--categories__title,
            .block--tags__title {
                display: inline-block;
            }

            .block--categories__list,
            .block--tags__list {
                display: inline-flex;
            }

            &__remove {
                font-size: $fontSizeReduced;
                text-decoration: underline;
                text-align: right;
            }

            .--active {
                font-weight: bold;
            }
        }

        .content-segment {
            &__header {
                margin-bottom: 1rem;
                text-align: left;
                
                h2 {
                    margin-bottom: 0;
                    line-height: 1.20;
                    margin-top: 0.5rem;
                    width: 80%;
                }
            }

            &__subtitle {
                width: 80%;
                margin: 0.15rem 0 0.5rem;
                font-size: $fontSizeReduced;
                color: $txtColorBase-branded;
            }  
            
            &__specs {
                font-size: $fontSizeReduced;
                display: inline-block;

                + .content-segment__specs {
                    &:before {
                        content: "|";
                        display: inline-block;
                        width: 1rem;
                        text-align: center;
                    }
                }
            }
        }
    }

    &__block-title {
        &--main {
            font-size: 1.35rem;
            font-weight: bold;
            line-height: 1.35;
            padding: 0.75rem 0;
            color: $txtColorBase-branded;
            text-align: center;
        }

        &--sub {
            text-align: center;
            margin-bottom: 1rem;
        }
    }

    .link--txt {
        &:after {
            content: "";
            position: relative;
            margin-left: 5px;
            top: 1px;
            width: 0; 
            height: 0; 
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent; 
            border-left: 7px solid $bgColor-branded-dark;
            display: inline-block;
        }

    }
}
