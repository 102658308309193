/* BRANDING */

.branding {
    height: 3rem;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: flex-start;

    @media only screen and (min-width: ($triggerMobileNav)) {
        height: 4rem;
    }

    img {
        cursor: pointer;
        margin-top: 1.35rem;
        @media only screen and (min-width: (64px * 6)) {
            margin-top: 0.15rem;
        }
        @media only screen and (min-width: ($triggerMobileNav)) {
            margin-top: 0.2rem;
            @at-root .--feature-all & {
                margin-top: 0.455rem;
            }
        }
    }

    &__logo {
        &--img {
            height: 3.2rem;
            z-index: 99;
            display: none;
            @media only screen and (min-width: (64px * 7)) {
                height: 5.5rem;
                display: block;
            }
            @media only screen and (min-width: ($triggerMobileNav)) {
                height: 6.5rem;
                @at-root .--feature-all & {
                    height: 7.5rem;
                }
            }
        }

        &--txt {
            width: 20vw;
            min-width: 125px;
            max-width: 200px;
            height: auto;
            margin-left: 0;
            @media only screen and (min-width: (64px * 7)) {
                margin-left: 0.4rem;           
            }
        }   
    }

    &__text-container {
        height: 3.35rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 1.1rem;
        //display: none;
        @media only screen and (min-width: (64px * 6)) {
           display: flex;
        }
        img {
            margin-top: 0;
            @media only screen and (min-width: (64px * 6)) {
                margin-top: 0.15rem;
            }
            @media only screen and (min-width: ($triggerMobileNav)) {
                //margin-top: 0.85rem;
            }
        }
    }
}
