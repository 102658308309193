/* BLOCKS */

.blocks {
    margin-bottom: 1rem;

    &--top10:not(:first-child) {
        margin-top: 1.5rem;
    }

    &__blocks-title {
        font-family: $brandfont;
        margin-top: 0.4rem;
        margin-bottom: 1rem;
        text-align: center;

        &--main {
            display: block;
            font-size: 1rem;
            font-weight: bold;
            line-height: 1.3;
            margin-bottom: 1rem;

            a {
                font-size: 1rem;
                color: #000;
                text-decoration: none;
            }

            // span {
            //     display: block;
            //     font-weight: normal;
            //     font-size: 0.8rem;
            // }

            + span {
                display: block;
                margin-top: -1rem;
            }
        }

        &--main-section {
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: normal;
        }

        &--main-cat {
            display: block;
            font-size: 1.3rem;
            font-weight: bold;
            line-height: 1.3;
            &:first-letter {
                text-transform: uppercase;
            }
        }
        &--main-sub {
            display: block;
            font-weight: normal;
            font-size: 0.8rem;
        }
        
        &--main-lang {
            text-transform: capitalize;
        }

        &--sub {
            display: block;
            //text-transform: uppercase;
            //font-size: $fontSizeReduced;
            font-size: 1rem;
            font-weight: bold;
            color: $txtColorBase;
            //margin-bottom: 0.8rem;
        }

        &--sub-link {
            display: block;
            //font-size: $fontSizeReduced;
            font-size: 1.5rem;
            margin-top: 0.2rem;
            text-align: center;
        }

        @at-root .blocks--newsletter & {
            margin-bottom: -0.5rem;
            @media only screen and (min-width: (64px*12)) {
                //margin-bottom: -1rem;
            }
        }
    }

    &__blocks-subtitle {
        display: block;
        font-size: $fontSizeReduced;
        margin-top: 0.2rem;
    }

    &__content {
        display: flex;
        flex-flow: column;
    }

    &__footer {
        display: flex;
        justify-content: center;
    }

    &--books {
        margin: 0 -0.25rem;

        .block--categories__subcats {
            display: none;
            margin: 0.6rem 0 1.4rem;
            column-count: 3;
            column-gap: 0.5rem;
            border: none;
            border-radius: 0.5rem;
            background-color: rgba(226, 184, 115, 0.35);
            padding: 0.5rem 1rem 0.65rem;

            @media only screen and (min-width: (64px*8)) {
                display: block;
            }
        }

        .block--categories__subcat {
            display: flex;
            font-size: $fontSizeReduced;
            cursor: pointer;
            text-align: left;
            padding: 0.15rem 0 0.15rem 1rem;;
           
            &::before {
                background-color: rgba(#000, 0.2);
                border-radius: 50%;
                content: "";
                display: inline-block;
                margin-top: 0.6rem;
                margin-right: 0.5rem;
                height: 0.35rem;
                width: 0.35rem;
                transform: translate(-0.75rem, -2px);
                position: absolute;
            }

            &.--active {
                font-weight: bold;
                border-radius: 0.3rem;
                color: $txtColorBase-branded;

                &:before {
                    background-color: rgba($color-dark-sb, 1);
                    border-radius: 50%;
                    content: "";
                    display: inline-block;
                    //margin-top: 0.4rem;
                    margin-right: 0.5rem;
                    height: 0.35rem;
                    width: 0.35rem;
                    transform: translate(-0.75rem, -2px);
                    position: absolute;
                }
            }
        }
    }

    &--authors {
        margin: 0 -0.25rem;
        .blocks__list {
            @include foldOutList($initialLines);
            .actionShowMore {
                font-size: $fontSizeReduced;
                margin-top: 0.5rem;
                cursor: pointer;
            }
        }
    }

    &__filters {
        position: relative;
    }
}